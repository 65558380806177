import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import routes from "../../routes/routes-data";
import { findUpper } from "../../utils/Utils";
import { PreviewAltCard, Icon, UserAvatar } from "../Component";

const AdminCard = ({ admin, authId, onDeleteClick }) => {
  return (
    <Col sm="6" lg="4" xxl="3" key={admin.id}>
      <PreviewAltCard className={"h-100"}>
        <div className="team">
          <div className="team-options">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem tag={Link} to={routes.ADMIN_EDIT(admin.id)}>
                      <Icon name="edit"></Icon>
                      <span>Edit</span>
                    </DropdownItem>
                  </li>

                  {authId !== admin.id && (
                    <>
                      <li className="divider"></li>
                      <li>
                        <DropdownItem
                          href="#"
                          onClick={() => onDeleteClick(admin.id)}
                        >
                          <Icon name="trash-empty"></Icon>
                          <span>Delete</span>
                        </DropdownItem>
                      </li>
                    </>
                  )}

                  {/* <li onClick={() => suspendUser(item.id)}>
                    <DropdownItem
                      href="#suspend"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="na"></Icon>
                      <span>Suspend User</span>
                    </DropdownItem>
                  </li> */}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="user-card user-card-s2">
            {admin.profile_pic ? (
              <UserAvatar
                theme={admin.avatarBg}
                className="lg"
                text={findUpper(admin.name)}
                image={admin.profile_pic.access_path}
              >
                <div className="status dot dot-lg dot-success"></div>
              </UserAvatar>
            ) : (
              <div className="user-avatar lg">
                <span>
                  {admin.name
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase())}
                </span>
                <div className="status dot dot-lg dot-success"></div>
              </div>
            )}

            <div className="user-info">
              <h6>{admin.name}</h6>
              <span className="sub-text">@{admin.username.toLowerCase()}</span>
            </div>
          </div>
          <div
            className="team-details"
            style={{
              maxWidth: "100%",
            }}
          >
            <p className="text-capitalize d-flex flex-wrap justify-content-center align-items-center">
              {admin.admin_roles?.map((role) => (
                <Badge className="badge-dim mr-1 mt-1" color="secondary">
                  {role.name.replaceAll("_", " ").toLowerCase()}
                </Badge>
              ))}
            </p>
          </div>
          <ul className="team-info">
            <li>
              <span>Contact</span>
              <span>
                (+{admin.country.phone_code}) {admin.phone_number}
              </span>
            </li>
            <li>
              <span>Email</span>
              <span> {admin.email}</span>
            </li>
            <li>
              <span>Country</span>
              <span>{admin.country.name}</span>
            </li>
          </ul>
          <div className="team-view">
            {authId == admin.id && (
              <Badge className="badge-dot" color="success">
                You
              </Badge>
            )}
            {/* <Link
              to={`${process.env.PUBLIC_URL}/user-details-regular/${item.id}`}
            >
              <Button color="primary" className="btn-block btn-dim">
                <span>View Profile</span>
              </Button>
            </Link> */}
          </div>
        </div>
      </PreviewAltCard>
    </Col>
  );
};

export default AdminCard;
