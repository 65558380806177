import { Fragment } from "react";
import { useDropzone } from "react-dropzone";
import { Col, FormGroup } from "reactstrap";
import { Button, Icon } from "../../Component";
import Image from "../../image/Image";
import styles from "./dropzone.module.css";
const Dropzone = ({
  label,
  icon,
  className,
  type,
  error,
  rightSide,
  set,
  model,
  name,
  placeholder,
  columnConfig,
  ...props
}) => {
  let Wrapper = columnConfig ? Col : Fragment;
  let onDrop = (acceptedFiles) => onImageChange(acceptedFiles);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  const onImageChange = (imagesArray) => {
    let items = [];
    for (let index = 0; index < imagesArray.length; index++) {
      items.push({
        file: imagesArray[index],
        id: Math.random(),
        url: URL.createObjectURL(imagesArray[index]),
        type: "local",
      });
    }

    set(name, [...model, ...items]);
  };

  const onRemoveImage = (id) => {
    set(
      name,
      model?.filter((img) => img.id !== id)
    );
  };
  return (
    <Col sm={12} className="">
      <FormGroup>
        {label && (
          <div className="form-label-group">
            <label className=" form-label text-capitalize" htmlFor="default-01">
              {label}
            </label>
          </div>
        )}

        <section className="position-relative">
          <div
            {...getRootProps({
              onClickCapture: (e) => {},
            })}
            className={`dropzone upload-zone dz-clickable ${
              error ? styles.dropzoneError : ""
            }`}
          >
            <input {...getInputProps()} />
            {!model?.length > 0 && (
              <div className="dz-message">
                <span className="dz-message-text">Drag and drop file</span>
                <span className="dz-message-or">or</span>
                <Button color="primary" type="button" onClick={open}>
                  Select
                </Button>
              </div>
            )}
            <div className={styles.dropzoneContainer}>
              {model?.map((file) => (
                <div
                  className=""
                  key={file.id}
                  onClick={() => onRemoveImage(file.id)}
                >
                  <Image
                    src={file.type === "local" ? file.url : file.url}
                    alt=""
                    width={150}
                    height={150}
                  />
                </div>
              ))}

              {model?.length > 0 && (
                <div>
                  <Button color="primary" type="button" onClick={open}>
                    <Icon name="plus"></Icon>
                  </Button>
                </div>
              )}
            </div>
          </div>
          {error && <span className="invalid">{error[0]}</span>}
        </section>
      </FormGroup>
    </Col>
  );
};

export default Dropzone;
