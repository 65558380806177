import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import routes from "../routes/routes-data";
import { AdminRoles } from "../utils/statics";

const SuperAdmin = (props) => {
  let isSuperAdmin = useSelector((store) =>
    store.auth.roles.some((role) => role === AdminRoles.SUPER_ADMIN)
  );

  const location = useLocation();
  return (
    <>
      {isSuperAdmin ? (
        <Outlet />
      ) : (
        <Navigate
          replace
          to={routes.ERROR}
          state={{ path: location.pathname, code: 403 }}
        />
      )}
    </>
  );
};

export default SuperAdmin;
