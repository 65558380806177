import React, { useState } from "react";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  Row,
} from "../../components/Component";
import { Link } from "react-router-dom";
import routes from "../../routes/routes-data";
import AdminCard from "../../components/cards/admin-card";
import useRequest from "../../hooks/use-request";
import { useCallback } from "react";
import { useEffect } from "react";
import apiRoutes from "../../routes/api-routes";

import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import NoRecords from "../../components/no-records/no-records";
import Overlay from "../../components/loaders/page-overlay";

const AdminsIndex = () => {
  const [admins, setAdmins] = useState([]);

  const { isLoading, sendRequest } = useRequest();

  const authId = useSelector((store) => store.auth.user.id);
  const getAdmins = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.ADMIN_INDEX,
    });

    if (response) {
      setAdmins(response.admins);
    }
  }, [sendRequest]);

  const deleteAdmin = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.ADMIN_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        setAdmins((prev) => prev.filter((a) => a.id != id));
        Swal.fire("Deleted!", "Admin has been deleted.", "success");
      }
    }
    return true;
  };

  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAdmin(id);
      }
    });
  };

  useEffect(() => {
    getAdmins();
  }, [getAdmins]);

  return (
    <React.Fragment>
      <Head title="User Contact - Card"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Admins</BlockTitle>
              <BlockDes className="text-soft">
                <p>You can create, update, delete Admins</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                {/* <Link
                  to={routes.ADMIN_CREATE}
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                >
                  <Icon name="menu-alt-r"></Icon>
                </Link> */}
                <div
                  className="toggle-expand-content"
                  style={{ display: "block" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Link
                        to={routes.ADMIN_CREATE}
                        className="btn btn-primary  btn-round btn-icon"
                      >
                        <Icon name="plus"></Icon>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {isLoading && <Overlay spinner={true} />}
          {!isLoading && admins?.length < 0 && <NoRecords name="admins" />}
          <Row className="g-gs align-items-stretch">
            {admins?.map((admin) => (
              <AdminCard
                admin={admin}
                authId={authId}
                onDeleteClick={onDeleteClick}
              />
            ))}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default AdminsIndex;
