import _ from "lodash";
import { Badge } from "reactstrap";
import { capitalizeText } from "../../../utils/methods";

const PaymentMethodBadge = ({ method }) => {
  let color = {
    CASH_ON_DELIVERY: "blue",
    PAYPAL: "info",
  };
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <Badge color={`outline-${color[method]}`} pill>
      {capitalizeText(method.toLowerCase())}
    </Badge>
  );
};

export default PaymentMethodBadge;
