import { useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Form, FormGroup } from "reactstrap";
import {
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
} from "../../../components/Component";
import Input from "../../../components/input/input/input";
import useForm from "../../../hooks/use-form";
import Head from "../../../layouts/dashboard/head/Head";
import apiRoutes from "../../../routes/api-routes";
import routes from "../../../routes/routes-data";

const LoginSection = ({ canRegister, request, onComplete, ...props }) => {
  const { isLoading, error, sendRequest, validationErros } = request;
  const [passState, setPassState] = useState(false);
  const { setOne, inputs } = useForm({
    identity: "",
    password: "",
  });
  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const response = await sendRequest({
        url: apiRoutes.ADMIN_LOGIN,
        method: "POST",
        body: inputs,
      });

      if (response) {
        onComplete(response, inputs);
      }
    }
  };
  return (
    <>
      <Head title="Login" />
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">Sign-In</BlockTitle>
          <BlockDes>
            <p>
              Access Bianca Dashbaord using your email or username and password.
            </p>
          </BlockDes>
        </BlockContent>
      </BlockHead>
      {/* On Credential Fails */}
      {error && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" />
            {error}
          </Alert>
        </div>
      )}
      <Form className="is-alter" onSubmit={onFormSubmit}>
        <Input
          size="form-control-lg"
          label="email or username"
          set={setOne}
          name="identity"
          model={inputs.email}
          error={validationErros?.identity}
        />
        <Input
          name="password"
          size="form-control-lg"
          set={setOne}
          model={inputs.password}
          error={validationErros?.password}
          label="password"
          type={passState ? "text" : "password"}
          rightSide={
            <a
              href="#password"
              onClick={(ev) => {
                ev.preventDefault();
                setPassState(!passState);
              }}
              className={`form-icon lg form-icon-right passcode-switch ${
                passState ? "is-hidden" : "is-shown"
              }`}
            >
              <Icon name="eye" className="passcode-icon icon-show"></Icon>

              <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
            </a>
          }
        />
        <FormGroup>
          <Button
            isLoading={isLoading}
            size="lg"
            className="btn-block"
            type="submit"
            color="primary"
          >
            Sign In
          </Button>
        </FormGroup>
      </Form>

      {canRegister && (
        <div className="form-note-s2 text-center pt-4">
          <Link to={routes.AUTH_REGISTER}>
            <strong>Create Your First Account</strong>
          </Link>
        </div>
      )}
    </>
  );
};

export default LoginSection;
