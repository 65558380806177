import { useLocation } from "react-router-dom";
import Error404 from "./404";
import Error403 from "./403";

const Error = (props) => {
  const { state } = useLocation();
  if (state.code === 404) {
    return <Error404 />;
  } else if (state.code === 403) {
    return <Error403 />;
  } else {
    return <Error404 />;
  }
};

export default Error;
