import { Link, Outlet, useLocation } from "react-router-dom";
import { Block, PreviewCard } from "../../components/Component";
import AuthFooter from "./auth-footer";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../dashboard/page-container/PageContainer";

const AuthLayout = ({ ...props }) => {
  const location = useLocation();

  return (
    <PageContainer>
      <Block
        className={`nk-block-middle nk-auth-body ${
          location.pathname == "/account/register"
            ? "wide-sm wide-xs"
            : "wide-xs"
        }`}
      >
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img
              className="logo-light logo-img logo-img-lg"
              src={Logo}
              alt="logo"
            />
            <img
              className="logo-dark logo-img logo-img-lg"
              src={LogoDark}
              alt="logo-dark"
            />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <Outlet />
        </PreviewCard>
      </Block>
      <AuthFooter />
    </PageContainer>
  );
};

export default AuthLayout;
