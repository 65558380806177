import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import Icon from "../icon/Icon";

const TooltipComponent = ({
  iconClass,
  icon,
  id,
  direction,
  text,
  containerClassName,
  tag: Tag,
  ...props
}) => {
  return (
    <React.Fragment>
      {Tag ? (
        <Tag className={containerClassName} id={id} {...props}>
          {" "}
          <Icon className={`${iconClass ? iconClass : ""}`} name={icon}></Icon>
        </Tag>
      ) : (
        <Icon
          className={`${iconClass ? iconClass : ""}`}
          name={icon}
          id={id}
        ></Icon>
      )}
      <UncontrolledTooltip autohide={false} placement={direction} target={id}>
        {text}
      </UncontrolledTooltip>
    </React.Fragment>
  );
};
export default TooltipComponent;
