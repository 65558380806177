import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useMatch } from "react-router-dom";
import routes from "../routes/routes-data";

const Authenticated = (props) => {
  const isAuth = useSelector((store) => store.auth.isAuthenticated);
  const location = useLocation();

  return (
    <>
      {isAuth ? (
        <Outlet />
      ) : (
        <Navigate
          replace
          to={routes.AUTH_LOGIN}
          state={{ path: location.pathname }}
        />
      )}
    </>
  );
};

export default Authenticated;
