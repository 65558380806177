import React, { forwardRef, useState } from "react";
import { Fragment } from "react";
import { Col, FormGroup } from "reactstrap";
import { Icon } from "../../Component";

const Textarea = ({
  size,
  label,
  icon,
  className,
  type,
  error,
  rightSide,
  set,
  model,
  name,
  placeholder,
  columnConfig,
  ...props
}) => {
  let Wrapper = columnConfig ? Col : Fragment;

  const onImageChange = (e) => {
    const imagesArray = e.target.files;

    if (props.multiple) {
      let items = [];
      for (let index = 0; index < imagesArray.length; index++) {
        items.push({
          file: imagesArray[index],
          id: Math.random(),
          url: URL.createObjectURL(imagesArray[index]),
        });
      }
      set(name, items);
    } else {
      set(name, {
        file: imagesArray[0],
        id: Math.random(),
        url: URL.createObjectURL(imagesArray[0]),
      });
    }
  };

  return (
    <Wrapper {...columnConfig}>
      <FormGroup>
        {label && (
          <div className="form-label-group">
            <label className=" form-label text-capitalize" htmlFor="default-01">
              {label}
            </label>
          </div>
        )}
        <div className="form-control-wrap">
          <textarea
            onChange={(e) => {
              set(name, e.target.value);
            }}
            {...props}
            className={`form-control ${size} ${error ? "error" : ""}`}
            placeholder={placeholder ?? `Enter your ${label ?? name}`}
          >
            {model}
          </textarea>

          {error && <span className="invalid">{error[0]}</span>}
        </div>
      </FormGroup>
    </Wrapper>
  );
};

export default Textarea;
