import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import HeadSection from "../../components/admin-page-components/HeadSection";
import { Block, BlockDes, Icon } from "../../components/Component";
import Button from "../../components/button/Button";
import useRequest from "../../hooks/use-request";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import routes from "../../routes/routes-data";
import Content from "../../layouts/dashboard/content/Content";

import Overlay from "../../components/loaders/page-overlay";
import Invoice from "./partials/invoice";
const OrdersInvoice = (props) => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const { isLoading, sendRequest } = useRequest();

  /*-------------------------------------------
            Functions
--------------------------------------------*/
  const onPrintClick = () => {
    var prtContent = document.getElementById("invoice-printable");
    let head = document.getElementsByTagName("head")[0];

    var WinPrint = window.open("", "", "");

    WinPrint.document.write(
      head.innerHTML +
        prtContent.innerHTML +
        `<script>
      document.getElementById("print-btn").addEventListener("click", () => window.print())
    </script>`
    );

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    // WinPrint.close();
  };
  /*-------------------------------------------
            Async Functions
--------------------------------------------*/
  const getOrder = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.ORDER_SHOW(orderId),
    });

    if (response) {
      setOrder(response);
    }
  }, [orderId, sendRequest]);
  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/
  useEffect(() => {
    getOrder();
  }, [getOrder]);

  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <>
      <Head title="Invoice Detail"></Head>
      <Content>
        {isLoading && (
          <Block>
            <Overlay spinner={true} bg />
          </Block>
        )}
        {order && (
          <>
            <HeadSection
              title={
                <>
                  Invoice{" "}
                  <strong className="text-primary small">#{order.id}</strong>
                </>
              }
              left={
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Created At:{" "}
                      <span className="text-base">{order.created_at}</span>
                    </li>
                  </ul>
                </BlockDes>
              }
              right={
                <>
                  <Link to={routes.ORDER_INDEX}>
                    <Button
                      color="light"
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                  </Link>
                  <Link to={routes.ORDER_INDEX}>
                    <Button
                      color="light"
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </>
              }
            />

            <Block>
              <Invoice order={order} onPrintClick={onPrintClick} />
            </Block>
          </>
        )}
      </Content>
    </>
  );
};

export default OrdersInvoice;
