import { Fragment, useEffect, useRef } from "react";
import { useQuill } from "react-quilljs";
import { Col, FormGroup } from "reactstrap";
import "quill/dist/quill.snow.css";
import "quill/dist/quill";

import "../../../assets/css/editor.css";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
var toolbarOptions = [[{ direction: "rtl" }]];
const Editor = ({
  label,
  className,
  error,
  set,
  model,
  name,
  placeholder,
  columnConfig,
  ...props
}) => {
  let Wrapper = columnConfig ? Col : Fragment;
  const directionRef = useRef();
  const { quillRef, quill, Quill } = useQuill({
    modules: {
      toolbar: toolbarOptions,
    },
    placeholder: placeholder ?? `Enter your ${label ?? name}`,
    formats: ["direction"],
  });

  useEffect(() => {
    if (quill && model) {
      quill.clipboard.dangerouslyPasteHTML(model);
    }
  }, [quill]);

  if (Quill && !quill) {
    // Add fonts to whitelist
    var Font = Quill.import("formats/font");

    // We do not add Aref Ruqaa since it is the default
    Font.whitelist = ["mirza", "roboto"];
    Quill.register(Font, true);
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        set(name, quill.root.innerHTML);
      });
    }
  }, [name, quill, set]);

  return (
    <Wrapper {...columnConfig}>
      <FormGroup>
        {label && (
          <div className="form-label-group">
            <label className=" form-label text-capitalize" htmlFor="default-01">
              {label}
            </label>
          </div>
        )}
        <div className="form-control-wrap">
          {/* <input
              onChange={(e) => {
                set(name, e.target.value);
              }}
              value={model}
              ref={ref}
              {...props}
              type={type ?? "text"}
              className={`form-control ${size} ${error ? "error" : ""}`}
              placeholder={placeholder ?? `Enter your ${label ?? name}`}
            /> */}

          <div
            className={` ${error ? "error-border" : ""}`}
            style={{ border: "1px solid transparent", borderRadius: "10px" }}
          >
            <div
              ref={quillRef}
              className="editor"
              style={{ width: "100%", height: 200 }}
            />
          </div>

          {error && <span className="invalid">{error[0]}</span>}
        </div>
      </FormGroup>
    </Wrapper>
  );
};

export default Editor;
