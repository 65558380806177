import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../../components/Component";
import { LinkList, LinkItem } from "../../../../../components/links/Links";
import UserAvatar from "../../../../../components/user/UserAvatar";
import { authActions } from "../../../../../store/auth-reducer";
import routes from "../../../../../routes/routes-data";
import apiRoutes from "../../../../../routes/api-routes";

import useRequest from "../../../../../hooks/use-request";
import { AdminRoles } from "../../../../../utils/statics";
import { findUpper } from "../../../../../utils/Utils";

const User = () => {
  const [open, setOpen] = useState(false);
  const { token, user, roles } = useSelector((store) => store.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggle = () => setOpen((prevState) => !prevState);

  const { isLoading, sendRequest } = useRequest();
  const handleSignout = async () => {
    if (!isLoading) {
      const response = await sendRequest({
        url: apiRoutes.ADMIN_LOGOUT,
        method: "POST",
        token: token,
      });
      if (response) {
        dispatch(authActions.onLogout());
        navigate(routes.AUTH_LOGIN);
      }
    }
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar
            theme={user.avatarBg}
            text={findUpper(user.name)}
            image={user.profile_pic?.access_path}
            // icon="user-alt"
            className="sm"
          ></UserAvatar>

          <div className="user-info d-none d-md-block">
            <div className="user-status text-capitalize">
              {roles.includes(AdminRoles.SUPER_ADMIN)
                ? AdminRoles.SUPER_ADMIN.replace("_", " ").toUpperCase()
                : Object.values(AdminRoles)
                    .filter((r) => r != AdminRoles.SUPER_ADMIN)
                    .every((role) => roles.includes(role))
                ? "PRIMARY ADMIN"
                : "SECONDARY ADMIN"}
            </div>
            <div
              className="user-name dropdown-indicator"
              style={{
                textTransform: "capitalize",
              }}
            >
              {user.name}
            </div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <UserAvatar
              theme={user.avatarBg}
              text={findUpper(user.name)}
              image={user.profile_pic?.access_path}
            ></UserAvatar>
            <div className="user-info">
              <span className="lead-text">@{user.username}</span>
              <span className="sub-text">{user.email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              link={routes.USER_SETTINGS_PROFILE}
              icon="user-alt"
              onClick={toggle}
            >
              View Profile
            </LinkItem>
            <LinkItem
              link={routes.USER_SETTINGS_SECURITY}
              icon="setting-alt"
              onClick={toggle}
            >
              Account Setting
            </LinkItem>
            <LinkItem
              link={routes.USER_SETTINGS_ACTIVITIES}
              icon="activity-alt"
              onClick={toggle}
            >
              Login Activity
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href="#" onClick={handleSignout}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
