import axios from "axios";

export class AxiosRequest {
  //    axiosInstance = axios.create({
  //   baseURL: process.env.NEXT_PUBLIC_BACKEND_API,
  //   timeout: 1000,
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   },
  // })

  async sendRequest(config) {
    try {
      let response = await axios.request({
        method: config.method ?? "GET",
        url: process.env.REACT_APP_BACKEND_API + config.url,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: config.token ? "Bearer " + config.token : "",
          ...config.headers,
        },
        data: config.body,
        params: config.queryParams,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
