import React from "react";
import Head from "../../layouts/dashboard/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  LoginLogTable,
  Button,
} from "../../components/Component";
import useRequest from "../../hooks/use-request";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import apiRoutes from "../../routes/api-routes";
import moment from "moment";
import Overlay from "../../components/loaders/page-overlay";
import { useOutletContext } from "react-router-dom";

const Activities = (props) => {
  const [sm, updateSm] = useOutletContext();
  const [loginActivities, setLoginActivities] = useState([]);
  const [currentLogin, setCurrentLogin] = useState(null);

  const { isLoading, sendRequest } = useRequest();

  const getLogins = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.ADMIN_LOGIN_ACTIVITIES,
    });

    if (response) {
      setLoginActivities(response.active_logins);
      setCurrentLogin(response.current_login);
    }
  }, [sendRequest]);

  const onDeleteClick = async (id) => {
    let response = await sendRequest({
      url: apiRoutes.ADMIN_REMOVE_TOKEN(id),
      method: "DELETE",
    });

    if (response) {
      setLoginActivities((prev) => prev.filter((token) => token.id != id));
    }
  };
  useEffect(() => {
    getLogins();
  }, [getLogins]);
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        {isLoading && <Overlay spinner shade />}
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Login Activity</BlockTitle>
            <BlockDes>
              <p>
                Here is your active login history{" "}
                <span className="text-soft">
                  <Icon name="info" />
                </span>
              </p>
            </BlockDes>
          </BlockHeadContent>

          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              color={""}
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <table className="table table-ulogs">
        <thead className="thead-light">
          <tr>
            <th className="tb-col-os">
              <span className="overline-title">
                Location <span className="d-sm-none">/ IP</span>
              </span>
            </th>
            <th className="tb-col-ip">
              <span className="overline-title">IP</span>
            </th>
            <th className="tb-col-time">
              <span className="overline-title">Time</span>
            </th>
            <th className="tb-col-action">
              <span className="overline-title">&nbsp;</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentLogin && (
            <tr key={currentLogin.id}>
              <td className="tb-col-os">
                {currentLogin.location?.countryName}

                <span className="badge badge-success ml-1">This</span>
                <span className="sub-text">
                  {currentLogin.location?.cityName},{" "}
                  {currentLogin.location?.regionName}
                </span>
              </td>
              <td className="tb-col-ip align-middle">
                <span className="sub-text">{currentLogin.location?.ip}</span>
              </td>
              <td className="tb-col-time align-middle">
                <span className="sub-text">
                  {moment(new Date(currentLogin.created_at.toString())).format(
                    "MMM DD, yyyy hh:mm A"
                  )}{" "}
                  <span className="d-none d-sm-inline-block">
                    {/* {item.time} */}
                  </span>
                </span>
              </td>
              <td className="tb-col-action align-middle text-success fs-16px">
                <Icon name="dot"></Icon>
              </td>
            </tr>
          )}
          {loginActivities?.map((item, idx) => {
            return (
              <tr key={item.id}>
                <td className="tb-col-os">
                  {item.location?.countryName}
                  <span className="sub-text">
                    {item.location?.cityName}, {item.location?.regionName}
                  </span>
                </td>
                <td className="tb-col-ip align-middle">
                  <span className="sub-text">{item.location?.ip}</span>
                </td>
                <td className="tb-col-time align-middle">
                  <span className="sub-text">
                    {moment(new Date(item.created_at.toString())).format(
                      "MMM DD, yyyy hh:mm A"
                    )}{" "}
                    <span className="d-none d-sm-inline-block">
                      {/* {item.time} */}
                    </span>
                  </span>
                </td>
                <td className="tb-col-action align-middle">
                  <a
                    href="#delete"
                    onClick={() => onDeleteClick(item.id)}
                    className="link-cross mr-sm-n1"
                  >
                    <Icon name="cross"></Icon>
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};
export default Activities;
