import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { Row, Button, Icon } from "../../../components/Component";
import ImageInput from "../../../components/input/image/image";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import { addAlert } from "../../../utils/alert";
import { BannerTypes } from "../../../utils/statics";
let swal;
const CreatUpdateModal = ({ show, onClose, banner, onSuccess, type }) => {
  const {
    isLoading,
    sendFormRequest,
    sendRequest,
    validationErros,
    resetValidationErrors,
  } = useRequest();
  const [categories, setCategories] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const { inputs, setOne, setFields, reset } = useForm({
    image: "",
    primary_title: "",
    description: "",
    link: "",
    type: "",
    category_id: "",
  });
  const typeOptions = Object.keys(BannerTypes).map((k) => ({
    label: k,
    value: BannerTypes[k],
  }));

  const getOptions = useCallback(async () => {
    const response = await sendRequest({
      url: apiRoutes.BANNER_OPTIONS,
    });

    if (response) {
      setCategories(response.categories);
    }
  }, [sendRequest]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  useEffect(() => {
    setCategoryOptions(
      categories.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      })
    );
  }, [categories]);

  useEffect(() => {
    if (banner) {
      console.log(banner);
      setFields({
        image: banner.image
          ? {
              id: banner.image?.id,
              file: null,
              url: banner.image?.access_path,
            }
          : null,
        primary_title: banner.primary_title ?? "",
        description: banner.description ?? "",
        link: banner.link ?? "",
        type: banner.type ?? "",
        category_id: banner.category_id ?? "",
      });
    }
  }, [banner, setFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const data = new FormData();
      data.append("primary_title", inputs.primary_title);
      data.append("description", inputs.description);
      data.append("link", inputs.link);
      data.append("type", inputs.type);
      data.append("category_id", inputs.category_id);
      data.append("image", inputs.image?.file ?? "");
      if (banner) {
        data.append("_method", "PATCH");
      }

      sendFormRequest({
        url: banner
          ? apiRoutes.BANNER_UPDATE(banner.id)
          : apiRoutes.BANNER_CREATE,
        method: banner ? "PATCH" : "POST",
        body: data,
      }).then((response) => {
        Swal.close();
        if (response) {
          reset();
          addAlert(
            toast.TYPE.SUCCESS,
            `Banner has been ${banner ? "updated" : "added"} successfully`
          );
          onClose();
          onSuccess();
        }
      });

      if (
        inputs.image?.type?.endsWith("mp4") ||
        inputs.image?.type?.endsWith("flv")
      ) {
        swal = Swal.fire({
          title: "Please Wait",
          text: "Video is uploading...",
          icon: "info",
          showCloseButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={() => {
          reset();
          resetValidationErrors();
          onClose();
        }}
        fullscreen
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            reset();
            resetValidationErrors();
            onClose();
          }}
          close={
            <button
              className="close"
              onClick={() => {
                reset();
                resetValidationErrors();
                onClose();
              }}
            >
              <Icon name="cross" />
            </button>
          }
        >
          {banner ? "Edit" : "Add"} Banner
        </ModalHeader>

        <form onSubmit={onSubmit}>
          <ModalBody>
            <Row className="g-3">
              <ImageInput
                accept={"video/* | image/*"}
                columnConfig={{ sm: 12 }}
                label="Banner Background"
                set={(key, value) => {
                  setOne(key, value);
                }}
                name="image"
                model={inputs.image}
                error={validationErros?.image}
                width="250px"
                removable={false}
              />

              <Input
                columnConfig={{ sm: 6 }}
                label="primary tite"
                set={setOne}
                name="primary_title"
                model={inputs.primary_title}
                error={validationErros?.primary_title}
              />
              <Input
                columnConfig={{ sm: 6 }}
                label="description"
                set={setOne}
                name="description"
                model={inputs.description}
                error={validationErros?.description}
              />
              <Select
                options={typeOptions}
                columnConfig={{ sm: 6 }}
                label="Type"
                set={setOne}
                name="type"
                model={inputs.type}
                error={validationErros?.type}
              />
              <Select
                options={categoryOptions}
                columnConfig={{ sm: 6 }}
                placeholder={"Select a category"}
                label="category"
                set={setOne}
                name="category_id"
                model={inputs.category_id}
                error={validationErros?.category_id}
              />
              <Input
                columnConfig={{ sm: 12 }}
                label="link"
                set={setOne}
                name="link"
                model={inputs.link}
                error={validationErros?.link}
              />
            </Row>
          </ModalBody>
          <ModalFooter className="bg-light">
            <Button isLoading={isLoading}>
              {banner ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default CreatUpdateModal;
