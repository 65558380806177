import { configureStore } from "@reduxjs/toolkit";

import authReducer, { authActions } from "./auth-reducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});

export default store;
