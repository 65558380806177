import React, { useEffect, useState } from "react";
import { NavLink, Link, useSearchParams, useLocation } from "react-router-dom";
import { removeQueryParam, scrollTop } from "../../utils/methods";
import {
  Pagination as PaginationWrapper,
  PaginationLink,
  PaginationItem,
} from "reactstrap";
import { Icon } from "../Component";
// current_page: 1;
// first_page_url: "http://127.0.0.1:8000/api/companies?page=1";
// from: 1;
// last_page: 4;
// last_page_url: "http://127.0.0.1:8000/api/companies?page=4";
// next_page_url: "http://127.0.0.1:8000/api/companies?page=2";
// path: "http://127.0.0.1:8000/api/companies";
// per_page: 1;
// prev_page_url: null;
// to: 1;
// total: 4;
const Pagination = ({
  data,
  searchParams,
  setSearchParams,
  //   path,
  //   setCurrentPage,
  //   currentPage,
  //   queryParams,
}) => {
  const location = useLocation();

  let currentPage = searchParams.get("page") ?? 1;
  let path = location.pathname;

  // preserve other query parmas than page
  let queryParams = removeQueryParam("page", location.search);

  useEffect(() => {
    scrollTop();
  }, [location.pathname]);

  let pages = [];

  for (let index = 1; index <= data.last_page; index++) {
    pages.push(
      <PaginationItem
        disabled={isNaN(index)}
        className={`d-none d-sm-block ${currentPage == index ? "active" : ""}`}
        key={index}
      >
        <PaginationLink
          tag={Link}
          to={{
            pathname: path,
            search: `?page=${index}${
              queryParams && queryParams.length > 0 ? "&" + queryParams : ""
            }`,
          }}
        >
          {index}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <PaginationWrapper aria-label="Page navigation example">
      {/* Go to First Page */}
      <PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
        <PaginationLink
          tag={Link}
          className="page-link-first"
          to={
            currentPage != 1
              ? {
                  pathname: path,
                  search: `?page=${1}${
                    queryParams && queryParams.length > 0
                      ? "&" + queryParams
                      : ""
                  }`,
                }
              : ""
          }
        >
          <Icon name="chevrons-left" />
        </PaginationLink>
      </PaginationItem>

      {/* Go to previous page */}
      <PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
        <PaginationLink
          tag={Link}
          className="page-link-prev"
          to={
            currentPage != 1
              ? {
                  pathname: path,
                  search: `?page=${currentPage - 1}${
                    queryParams && queryParams.length > 0
                      ? "&" + queryParams
                      : ""
                  }`,
                }
              : ""
          }
        >
          <Icon name="chevron-left" />
        </PaginationLink>
      </PaginationItem>
      {pages}

      {/* Go to next page */}
      <PaginationItem disabled={data.last_page == currentPage}>
        <PaginationLink
          tag={Link}
          className="page-link-next"
          to={{
            pathname: path,
            search: `?page=${+currentPage + 1}${
              queryParams && queryParams.length > 0 ? "&" + queryParams : ""
            }`,
          }}
        >
          <Icon name="chevron-right" />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={data.last_page == currentPage}>
        <PaginationLink
          tag={Link}
          className="page-link-next"
          to={{
            pathname: path,
            search: `?page=${data.last_page}${
              queryParams && queryParams.length > 0 ? "&" + queryParams : ""
            }`,
          }}
        >
          <Icon name="chevrons-right" />
        </PaginationLink>
      </PaginationItem>
    </PaginationWrapper>
  );
};
export default Pagination;
