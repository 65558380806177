import React, { forwardRef, useState } from "react";
import { Fragment } from "react";
import { Col, FormGroup } from "reactstrap";
import { Icon } from "../../Component";

const Input = forwardRef(
  (
    {
      size,
      label,
      icon,
      className,
      type,
      error,
      rightSide,
      set,
      model,
      name,
      placeholder,
      columnConfig,
      ...props
    },
    ref
  ) => {
    let Wrapper = columnConfig ? Col : Fragment;

    const onImageChange = (e) => {
      const imagesArray = e.target.files;

      if (props.multiple) {
        let items = [];
        for (let index = 0; index < imagesArray.length; index++) {
          items.push({
            file: imagesArray[index],
            id: Math.random(),
            url: URL.createObjectURL(imagesArray[index]),
          });
        }
        set(name, items);
      } else {
        set(name, {
          file: imagesArray[0],
          id: Math.random(),
          url: URL.createObjectURL(imagesArray[0]),
        });
      }
    };

    return (
      <Wrapper {...columnConfig}>
        <FormGroup>
          {label && (
            <div className="form-label-group">
              <label
                className=" form-label text-capitalize"
                htmlFor="default-01"
              >
                {label}
              </label>
            </div>
          )}
          <div className="form-control-wrap">
            {rightSide ??
              (icon && (
                <div className="form-icon form-icon-right xl">
                  <Icon name={icon}></Icon>
                </div>
              ))}
            {type == "file" ? (
              <div className="custom-file">
                <input
                  type="file"
                  ref={ref}
                  {...props}
                  onChange={onImageChange}
                  className="custom-file-input"
                  id="customMultipleFiles"
                />
                <label
                  className={`custom-file-label ${error ? "error-border" : ""}`}
                  htmlFor="customMultipleFiles"
                >
                  {props.multiple
                    ? model?.length > 0
                      ? model?.length + " File(s) selected"
                      : "Choose files"
                    : ""}
                  {!props.multiple
                    ? model != "" && model != null
                      ? model.file?.name
                      : placeholder ?? "Choose file"
                    : ""}
                </label>
              </div>
            ) : (
              <input
                onChange={(e) => {
                  set(name, e.target.value);
                }}
                value={model}
                ref={ref}
                {...props}
                type={type ?? "text"}
                className={`form-control ${size} ${error ? "error" : ""}`}
                placeholder={placeholder ?? `Enter your ${label ?? name}`}
              />
            )}
            {error && <span className="invalid">{error[0]}</span>}
          </div>
        </FormGroup>
      </Wrapper>
    );
  }
);

export default Input;
