import React, { useEffect, useState } from "react";
import Head from "../../layouts/dashboard/head/Head";

import { Modal, ModalBody, Badge, Alert } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
} from "../../components/Component";

import { useSelector } from "react-redux";
import useRequest from "../../hooks/use-request";
import { useDispatch } from "react-redux";
import { Form, useNavigate, useOutletContext } from "react-router-dom";
import useForm from "../../hooks/use-form";
import apiRoutes from "../../routes/api-routes";
import { useCallback } from "react";
import Input from "../../components/input/input/input";
import Select from "../../components/input/select/select";
import ImageInput from "../../components/input/image/image";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import { authActions } from "../../store/auth-reducer";
import PhoneNumberInput from "../../components/input/phone-number-input/phone-number-input";

const Profile = (props) => {
  const [sm, updateSm] = useOutletContext();
  const { token, user, roles } = useSelector((store) => store.auth);

  const [modal, setModal] = useState(false);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const {
    isLoading,
    sendRequest,
    sendFormRequest,
    validationErros,
    resetValidationErrors,
    error,
  } = useRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOne, inputs, setFields } = useForm({
    email: "",
    name: "",
    username: "",
    phone_number: "",
    country_id: "",
    profile_pic: null,
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("admin", user.id);
    data.append("email", inputs.email);
    data.append("name", inputs.name);
    data.append("username", inputs.username);
    data.append("phone_number", inputs.phone_number);
    data.append("country_id", inputs.country_id);
    data.append("profile_pic", inputs.profile_pic?.file ?? "");
    data.append("_method", "PATCH");
    data.append("image_changed", isImageChanged);
    if (!isLoading) {
      const response = await sendFormRequest({
        url: apiRoutes.ADMIN_PROFILE_UPDATE,
        body: data,
      });

      if (response) {
        dispatch(
          authActions.initialize({
            user: response.admin,
          })
        );
        setModal(false);
        setIsImageChanged(false);
        addAlert(toast.TYPE.SUCCESS, "Your profile info updated successfully");
      }
    }
  };

  const getCountry = useCallback(async () => {
    const response = await sendRequest({
      url: apiRoutes.COUNTRIES_INDEX,
    });

    if (response) {
      setCountries(response);
      setCountryOptions(
        response.map((c) => ({
          value: c.id,
          label: `${c.name} (+${c.phone_code})`,
        }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    getCountry();
  }, [getCountry]);
  useEffect(() => {
    if (user) {
      setFields({
        email: user.email,
        name: user.name,
        username: user.username,
        phone_number: user.phone_number,
        country_id: user.country_id,
        profile_pic: user.profile_pic
          ? {
              id: user.profile_pic?.id,
              file: null,
              url: user.profile_pic?.access_path,
            }
          : null,
      });
    }
  }, [modal, setFields, user]);

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
            <BlockDes>
              <p>
                Basic info, like your name and address, that you use on Nio
                Platform.
              </p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              color={""}
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Display Name</span>
              <span className="data-value">{user.name}</span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Username</span>
              <span className="data-value">{user.username}</span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{user.email}</span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Phone Number</span>
              <span className="data-value text-soft">{user.phone_number}</span>
            </div>
          </div>
          <div className=" mt-4 mx-auto text-center">
            <Button outline color={"light"} onClick={() => setModal(true)}>
              Edit Info
            </Button>
          </div>
        </div>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Roles</h6>
          </div>

          {roles?.map((role) => (
            <Badge className="badge-md mr-2 mt-2" pill color="outline-gray">
              <Badge className="badge-dot text-capitalize" color="gray">
                {" "}
                {role.replaceAll("_", " ").toLowerCase()}
              </Badge>
            </Badge>
          ))}
        </div>
      </Block>

      <Modal
        isOpen={modal}
        className="modal-dialog-centered"
        size="lg"
        toggle={() => {
          resetValidationErrors();
          setIsImageChanged(false);
          setModal(false);
        }}
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              resetValidationErrors();
              setIsImageChanged(false);
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Update Profile</h5>
            <div className="tab-content">
              <form onSubmit={onFormSubmit}>
                <Row className="g-4">
                  <ImageInput
                    set={(key, value) => {
                      setIsImageChanged(true);
                      setOne(key, value);
                    }}
                    columnConfig={{ sm: 12 }}
                    label="Profile Pic (Optional)"
                    name="profile_pic"
                    model={inputs.profile_pic}
                    type="circle"
                    error={validationErros?.profile_pic}
                  />
                  <Input
                    columnConfig={{ sm: 12 }}
                    label="full name"
                    set={setOne}
                    name="name"
                    model={inputs.name}
                    error={validationErros?.name}
                  />
                  <PhoneNumberInput
                    columnConfig={{ sm: 6 }}
                    country={countries?.find((c) => c.id == inputs.country_id)}
                    label="phone number"
                    set={setOne}
                    name="phone_number"
                    model={inputs.phone_number}
                    error={validationErros?.phone_number}
                  />
                  <Select
                    options={countryOptions}
                    columnConfig={{ sm: 6 }}
                    label="country"
                    set={setOne}
                    name="country_id"
                    model={inputs.country_id}
                    error={validationErros?.country_id}
                  />

                  <Input
                    columnConfig={{ sm: 6 }}
                    label="email"
                    set={setOne}
                    name="email"
                    model={inputs.email}
                    error={validationErros?.email}
                  />
                  <Input
                    columnConfig={{ sm: 6 }}
                    label="username"
                    set={setOne}
                    name="username"
                    model={inputs.username}
                    error={validationErros?.username}
                  />
                  {error && (
                    <div className="">
                      <Alert color="danger" className="alert-icon">
                        {error}
                      </Alert>
                    </div>
                  )}
                  <Col md={6} className="offset-md-3 mt-3">
                    <Button
                      isLoading={isLoading}
                      size="lg"
                      className="btn-block"
                      type="submit"
                      color="primary"
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default Profile;
