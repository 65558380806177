import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, DropdownItem, Tooltip } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import { searchParamsToObject } from "../../utils/methods";
import CreatUpdateModal from "./partials/create-update-modal";
import HeadSection from "../../components/admin-page-components/HeadSection";
import Button from "../../components/button/Button";
import Swal from "sweetalert2";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import routes from "../../routes/routes-data";
import { useSelector } from "react-redux";
import Image from "../../components/image/Image";

const CategoriesIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { parentId } = useParams();

  const [categories, setCategories] = useState([]);
  const [mainCategory, setMainCategory] = useState(null);
  const { isLoading, sendRequest, error } = useRequest();
  const { sendRequest: sendRequestFeatured } = useRequest();

  let location = useLocation();

  const getCategories = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.CATEGORY_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
        parent_id: parentId,
      },
    });
    if (response?.categories) {
      setMainCategory(response.main);
      setCategories(
        response.categories.data?.length > 0 ? response.categories.data : []
      );
      delete response.categories.data;
      setPaginationData(
        response.categories.last_page != 1 ? response.categories : null
      );
    }
  }, [parentId, searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.CATEGORY_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Category has been deleted successfully");
        getCategories();
      }
      return true;
    }
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `${
        !parentId ? "Corresponding sub categories also will be deleted." : ""
      } You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  const onFeaturedClick = async (id) => {
    setCategories((prev) => {
      return prev?.map((cat) => {
        if (cat.id == id) {
          return {
            ...cat,
            is_featured: !cat.is_featured,
          };
        } else {
          return cat;
        }
      });
    });
    await sendRequestFeatured({
      url: apiRoutes.CATEGORY_UPDATE_FEATURED(id),
      method: "PUT",
    });
  };

  useEffect(() => {
    setCategories([]);
  }, [location.pathname]);
  useEffect(() => {
    getCategories();
  }, [getCategories, searchParams]);

  return (
    <>
      <Head title="Categories"></Head>
      <Content>
        <HeadSection
          left={
            parentId && (
              <Breadcrumb className="breadcrumb-arrow">
                <BreadcrumbItem>
                  <Link
                    to={{
                      pathname: routes.CATEGORY_INDEX,
                    }}
                  >
                    Categories
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{mainCategory?.name}</BreadcrumbItem>
              </Breadcrumb>
            )
          }
          title={!parentId && "Categories"}
          right={
            <Button
              className="d-inline-flex"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              <Icon name="plus"></Icon>
              <span className="d-none d-md-block">
                Add {parentId ? "Sub" : "Main"}
              </span>
            </Button>
          }
        />
        {/* ? = SUB CATEGORIES PAGE VIEW | : = MAIN CATEGORIES PAGE VIEW  */}
        {parentId ? (
          <PageTable
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            searchPlaceholder={"Search by category name, id"}
            title={`${mainCategory?.name ?? "Sub"} Categories`}
            isLoading={isLoading}
            error={error}
            headers={["name"]}
            actions={true}
            body={categories?.map((category) => [
              <span className="tb-product">
                <Image
                  src={category.image?.access_path}
                  alt="product"
                  className="thumb"
                  style={{
                    width: "100px",
                    height: "60px",
                    objectFit: "cover",
                  }}
                />
                <span className="title text-capitalize">{category.name}</span>
              </span>,
              [
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => {
                        setSelectedCategory(category);
                        setShowModal(true);
                      }}
                    >
                      <Icon name="edit"></Icon>
                      <span>Edit</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        setSelectedCategory(category);
                        setShowModal(true);
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"edit" + category.id}
                        icon="edit"
                        direction="top"
                        text="Edit Category"
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => onDeleteClick(category.id)}
                    >
                      <Icon name="trash-empty"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteClick(category.id)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"delete" + category.id}
                        icon="trash-empty"
                        direction="top"
                        text="Delete Category"
                      />
                    </li>
                  ),
                },
              ],
            ])}
            paginationData={paginationData}
          />
        ) : (
          <PageTable
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            searchPlaceholder={"Search by category name, id"}
            title={`All  Categories`}
            isLoading={isLoading}
            error={error}
            headers={[
              "name",
              "sub-categories",
              <TooltipComponent
                direction={"top"}
                id="is_fav"
                icon={"star-fill"}
                text="Mark category as featured"
                iconClass={"tb-asterisk"}
              ></TooltipComponent>,
            ]}
            actions={true}
            body={categories?.map((category) => [
              <span className="tb-product">
                <Image
                  src={category.image?.access_path}
                  alt="product"
                  className="thumb"
                  style={{
                    width: "100px",
                    height: "60px",
                    objectFit: "cover",
                  }}
                />
                <span className="title text-capitalize">{category.name}</span>
              </span>,
              category.sub_categories_count,

              <div className="asterisk tb-asterisk">
                <div
                  className={category.is_featured ? "active" : ""}
                  onClick={() => onFeaturedClick(category.id)}
                >
                  <Icon name="star" className="asterisk-off"></Icon>
                  <Icon name="star-fill" className="asterisk-on"></Icon>
                </div>
              </div>,
              [
                !parentId && {
                  dropdown: (
                    <DropdownItem
                      tag={Link}
                      to={routes.SUB_CATEGORY_INDEX(category.id)}
                    >
                      <Icon name="tags-fill"></Icon>
                      <span>Sub Categories</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() =>
                        navigate(routes.SUB_CATEGORY_INDEX(category.id))
                      }
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"sub" + category.id}
                        icon="tags-fill"
                        direction="top"
                        text="Sub Categories"
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => {
                        setSelectedCategory(category);
                        setShowModal(true);
                      }}
                    >
                      <Icon name="edit"></Icon>
                      <span>Edit</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        setSelectedCategory(category);
                        setShowModal(true);
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"edit" + category.id}
                        icon="edit"
                        direction="top"
                        text="Edit Category"
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => onDeleteClick(category.id)}
                    >
                      <Icon name="trash-empty"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteClick(category.id)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"delete" + category.id}
                        icon="trash-empty"
                        direction="top"
                        text="Delete Category"
                      />
                    </li>
                  ),
                },
              ],
            ])}
            paginationData={paginationData}
          />
        )}
      </Content>
      <CreatUpdateModal
        category={selectedCategory}
        parentId={parentId}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedCategory(null);
        }}
        onSuccess={() => getCategories()}
      />
    </>
  );
};

export default CategoriesIndex;
