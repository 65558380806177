import React from "react";
import classNames from "classnames";
import { Col } from "../Component";

export const OverlineTitle = ({ className, alt, ...props }) => {
  const classes = classNames({
    [`${className}`]: className,
    "overline-title": true,
    "mt-2": true,
    "overline-title-alt": alt,
  });
  return (
    <React.Fragment>
      <Col size={12}>
        {!props.tag ? (
          <h6 className={classes}>{props.children}</h6>
        ) : (
          <props.tag className={classes}>{props.children}</props.tag>
        )}
      </Col>
    </React.Fragment>
  );
};
