import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, DropdownItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../../components/Component";
import PageTable from "../../../components/admin-page-components/PageTable";
import useRequest from "../../../hooks/use-request";
import Content from "../../../layouts/dashboard/content/Content";
import Head from "../../../layouts/dashboard/head/Head";
import apiRoutes from "../../../routes/api-routes";
import { searchParamsToObject } from "../../../utils/methods";
import CreatUpdateModal from "./partials/create-update-modal";
import HeadSection from "../../../components/admin-page-components/HeadSection";
import Button from "../../../components/button/Button";
import Swal from "sweetalert2";
import { addAlert } from "../../../utils/alert";
import { toast } from "react-toastify";
import routes from "../../../routes/routes-data";

const ShippingCountriesIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [duplicateData, setDuplicateData] = useState({});
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { carrierId } = useParams();
  const [countries, setCountries] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();

  const getCountries = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.CARRIER_COUNTRY_INDEX(carrierId),
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response?.data) {
      setCountries(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.CARRIER_COUNTRY_DELETE(carrierId, id),
        method: "DELETE",
      });
      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Country has been deleted successfully");
        getCountries();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };
  useEffect(() => {
    getCountries();
  }, [getCountries, searchParams]);

  return (
    <>
      <Head title="Countries"></Head>
      <Content>
        <HeadSection
          left={
            <Breadcrumb className="breadcrumb-arrow">
              <BreadcrumbItem>
                <Link to={routes.CARRIER_INDEX}>Carriers</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Countries</BreadcrumbItem>
            </Breadcrumb>
          }
          right={
            <Button
              className="d-inline-flex"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              <Icon name="plus"></Icon>
              <span className="d-none d-md-block">Add Country</span>
            </Button>
          }
        />
        <PageTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchPlaceholder={"Search by country name, id"}
          title={"Countries"}
          isLoading={isLoading}
          error={error}
          headers={["id", "name", "standard", "delivery"]}
          actions={true}
          body={countries?.map((country) => {
            return [
              country.id,
              <span className="text-capitalize">{country.country_name}</span>,
              <span className="text-capitalize">{country.standard}</span>,
              <span>
                {country.day_from + " - " + country.day_to + " days"}{" "}
              </span>,
              [
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => {
                        setDuplicateData(country);
                        setShowModal(true);
                      }}
                    >
                      <Icon name="copy"></Icon>
                      <span>Duplicate</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        setDuplicateData(country);
                        setShowModal(true);
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"duplicate" + country.id}
                        icon="copy"
                        direction="top"
                        text="Duplicate"
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => {
                        setSelectedCountry(country);
                        setShowModal(true);
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>View / Edit</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        setSelectedCountry(country);
                        setShowModal(true);
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"edit" + country.id}
                        icon="eye"
                        direction="top"
                        text="View / Edit Country"
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => onDeleteClick(country.id)}
                    >
                      <Icon name="trash-empty"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteClick(country.id)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"delete" + country.id}
                        icon="trash-empty"
                        direction="top"
                        text="Delete Country"
                      />
                    </li>
                  ),
                },
              ],
            ];
          })}
          paginationData={paginationData}
          // filterOptions={[
          //   {
          //     label: "sort by",
          //     name: "sort",
          //     options: [
          //       { value: "any s", label: "Any" },
          //       { value: "ZA", label: "Z-A" },
          //       { value: "AZ", label: "A-Z" },
          //       { value: "ZA", label: "Z-A" },
          //     ],
          //   },
          //   {
          //     label: "order by",
          //     name: "order",
          //     options: [
          //       { value: "any o", label: "Any" },
          //       { value: "ZA", label: "Z-A" },
          //       { value: "AZ", label: "A-Z" },
          //       { value: "ZA", label: "Z-A" },
          //     ],
          //   },
          // ]}
        />
      </Content>
      <CreatUpdateModal
        country={selectedCountry}
        duplicateData={duplicateData}
        show={showModal}
        carrierId={carrierId}
        onClose={() => {
          setShowModal(false);
          setSelectedCountry(null);
          setDuplicateData({});
        }}
        onSuccess={() => getCountries()}
      />
    </>
  );
};

export default ShippingCountriesIndex;
