import React, { useState, useEffect } from "react";
import Content from "../dashboard/content/Content";
import { Link, Outlet } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component";
import { findUpper } from "../../utils/Utils";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useSelector } from "react-redux";
import routes from "../../routes/routes-data";

const UserSettingsLayout = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const { token, user, roles } = useSelector((store) => store.auth);

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar
                      theme={user.avatarBg}
                      text={findUpper(user.name)}
                      image={user.profile_pic?.access_path}
                    ></UserAvatar>
                    <div className="user-info">
                      <span className="lead-text">{user.name}</span>
                      <span className="sub-text">{user.email}</span>
                    </div>
                    <div className="user-action">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="btn btn-icon btn-trigger mr-n2"
                        >
                          <Icon name="more-v"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="camera-fill"></Icon>
                                <span>Change Photo</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="edit-fill"></Icon>
                                <span>Update Profile</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>

                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={routes.USER_SETTINGS_PROFILE}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}${routes.USER_SETTINGS_PROFILE}`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Personal Information</span>
                      </Link>
                    </li>
                    {/* <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-notification`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-profile-notification`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="bell-fill"></Icon>
                        <span>Notification</span>
                      </Link>
                    </li> */}
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={routes.USER_SETTINGS_ACTIVITIES}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}${routes.USER_SETTINGS_ACTIVITIES}`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="activity-round-fill"></Icon>
                        <span>Login Activity</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={routes.USER_SETTINGS_SECURITY}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}${routes.USER_SETTINGS_SECURITY}`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Security Setting</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <Outlet context={[sm, updateSm]} />
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserSettingsLayout;
