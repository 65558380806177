import { Spinner } from "reactstrap";

const Overlay = ({ bg, spinner, shade }) => {
  return (
    <div
      className={`page-overlay ${bg ? "page-overlay-bg" : ""} ${
        shade ? "page-overlay-shade" : ""
      }`}
    >
      {spinner && <Spinner />}
    </div>
  );
};

export default Overlay;
