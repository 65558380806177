import useForm from "../../../hooks/use-form";
import { Form, Row, Col } from "reactstrap";
import {
  BlockBetween,
  BlockHeadContent,
  Button,
} from "../../../components/Component";
import useRequest from "../../../hooks/use-request";
import Dropzone from "../../../components/input/dropzone/dropzone";
import Input from "../../../components/input/input/input";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import Editor from "../../../components/input/editor/editor";
import apiRoutes from "../../../routes/api-routes";
import { useSelector } from "react-redux";
import Select from "../../../components/input/select/select";
import CheckboxRadio from "../../../components/input/checkbox-radio/checkbox-radio";
const Details = ({
  isOptionUpdated,
  setIsOptionUpdated,
  stepState: { hasNext, next },
  onUnmount,
  productId,
  fields,
  ...props
}) => {
  const { isLoading, validationErros, sendRequest, sendFormRequest } =
    useRequest();
  const { inputs, setOne, setFields } = useForm({});
  const [categories, setCategories] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [sizeGuideOptions, setSizeGuideOptions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getOptions = useCallback(async () => {
    const response = await sendRequest({
      url: apiRoutes.PRODUCT_OPTIONS,
    });

    if (response) {
      setCarrierOptions(
        response?.carriers?.map((c) => ({
          value: c.id,
          label: c.name,
        }))
      );
      setSizeGuideOptions(
        response?.size_guides?.map((guide) => ({
          value: guide.id,
          label: guide.name,
        }))
      );
      setCategories(response.categories);
    }
  }, [sendRequest]);

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    //BASIC INFO
    data.append("name", inputs.name);
    data.append("description", inputs.description);
    data.append("size_guide_id", inputs.size_guide_id ?? "");
    data.append("is_featured", inputs.is_featured ? 1 : 0);

    // CATEGORIES
    inputs.categories?.forEach((cat, index) => {
      data.append(`categories[${index}]`, cat.value);
    });

    //IMAGES
    inputs.images
      ?.filter((img) => !img.is_existing)
      ?.forEach((image, index) => {
        if (image?.file) {
          data.append(`images[${index}]`, image.file);
        }
      });

    // EXISTING IMAGES IF EXISTS
    if (productId) {
      inputs.images
        ?.filter((img) => img.is_existing)
        ?.forEach((img, index) => {
          data.append(`existing_images[${index}]`, img.id);
        });
    }

    // CARRIERS
    inputs.carriers?.forEach((carrier, index) => {
      data.append(`carriers[${index}]`, carrier.value);
    });

    if (!isLoading) {
      let response = await sendFormRequest({
        url: apiRoutes.PRODUCT_VALIDATE_DETAILS,
        body: data,
      });
      if (response) {
        onUnmount(inputs);
        hasNext && next();
      }
    }
  };

  useEffect(() => {
    setFields(fields);
  }, [fields, setFields]);

  useEffect(() => {
    setCategoryOptions(
      categories.map((c) => {
        return {
          options: c.sub_categories?.map((s) => ({
            value: s.id,
            label: s.name,
          })),
          label: c.name,
        };
      })
    );
  }, [categories]);

  useEffect(() => {
    getOptions();
    setLoaded(true);
  }, [getOptions]);

  return (
    <div className="">
      <Form onSubmit={onFormSubmit}>
        <Row className="g-4">
          <Dropzone
            columnConfig={{ sm: 12 }}
            label="Images"
            set={setOne}
            name="images"
            model={inputs.images}
            error={validationErros?.images}
          />
          <Input
            columnConfig={{ sm: 12 }}
            label="name"
            set={setOne}
            name="name"
            model={inputs.name}
            error={validationErros?.name}
          />

          <Select
            options={categoryOptions}
            columnConfig={{ sm: 6 }}
            placeholder={"Select a category"}
            group
            label="category"
            set={
              setOne
            }
            multiple
            name="categories"
            model={inputs.categories}
            error={validationErros?.categories}
          />

          <Select
            options={carrierOptions}
            multiple
            columnConfig={{ sm: 6 }}
            label="shipping carriers"
            set={setOne}
            name="carriers"
            model={inputs.carriers}
            error={validationErros?.carriers}
          />
          <Select
            options={sizeGuideOptions}
            columnConfig={{ sm: 6 }}
            label="Size Guide (Optional)"
            set={setOne}
            name="size_guide_id"
            model={inputs.size_guide_id}
            error={validationErros?.size_guide_id}
          />
          <Editor
            columnConfig={{ sm: 12 }}
            label="description"
            set={setOne}
            name="description"
            model={inputs.description}
            error={validationErros?.description}
          />
          <Col sm={12} className=" mt-3">
            <BlockBetween>
              <BlockHeadContent></BlockHeadContent>
              <BlockHeadContent>
                <CheckboxRadio
                  className={"mb-4"}
                  options={[
                    {
                      label: "Is Featured",
                      value: 1,
                    },
                  ]}
                  columnConfig={{ sm: 12 }}
                  type={"checkbox"}
                  set={setOne}
                  name="is_featured"
                  model={inputs.is_featured}
                  error={validationErros?.is_featured}
                />
                <Button
                  isLoading={isLoading}
                  size="lg"
                  className={"m-auto d-block"}
                  type="submit"
                  color="primary"
                >
                  Next
                </Button>
              </BlockHeadContent>
            </BlockBetween>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Details;
