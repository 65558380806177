import React from "react";
import classNames from "classnames";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";

const Button = ({
  color,
  size,
  className,
  outline,
  disabled,
  isLoading,
  to,
  ...props
}) => {
  let clr = color ?? "primary";
  const buttonClass = classNames({
    btn: true,
    [`btn-${clr}`]: !outline,
    [`btn-outline-${clr}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });
  if (to) {
    return (
      <Link className={`${buttonClass} text-capitalize`} to={to} {...props}>
        {isLoading ? <Spinner size="sm" color="light" /> : props.children}
      </Link>
    );
  }
  return (
    <button className={`${buttonClass} text-capitalize`} {...props}>
      {isLoading ? <Spinner size="sm" color="light" /> : props.children}
    </button>
  );
};
export default Button;
