import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import { capitalizeText, searchParamsToObject } from "../../utils/methods";
import HeadSection from "../../components/admin-page-components/HeadSection";
import { CustomerStatus } from "../../utils/statics";
import StatusBadge from "./partials/status-badge";
import ViewModal from "./partials/view-modal";

const CustomersIndex = (props) => {
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [view, setView] = useState(null);
  const [customers, setCustomers] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();
  const { sendRequest: sendRequestStatus, isLoading: statusIsLoading } =
    useRequest();

  const getCustomers = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.CUSTOMER_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response?.data) {
      setCustomers(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onStatusClick = async (customerId, status) => {
    if (!statusIsLoading) {
      let response = await sendRequestStatus({
        url: apiRoutes.CUSTOMER_UPDATE(customerId),
        method: "PATCH",
        body: {
          status: status,
        },
      });
      if (response) {
        setCustomers((prev) =>
          prev?.map((customer) => {
            if (customerId == customer.id) {
              customer.status = status;
              return customer;
            } else {
              return customer;
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    getCustomers();
  }, [getCustomers, searchParams]);

  return (
    <>
      <ViewModal customer={view} onClose={() => setView(null)} />
      <Head title="Customers"></Head>
      <Content>
        <HeadSection title={"Customers"} />
        <PageTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchPlaceholder={"Search by customer name, id"}
          title="All Customers"
          isLoading={isLoading}
          error={error}
          headers={["id", "name", "orders", "method", "status"]}
          actions={true}
          body={customers?.map((customer) => {
            return [
              customer.id,
              <span className="text-capitalize ">
                {customer.first_name + " " + customer.last_name}
              </span>,
              customer.orders_count,
              <TooltipComponent
                tag="span"
                containerClassName="btn"
                id={"method" + customer.id}
                icon={
                  customer.driver == "google"
                    ? "google"
                    : customer.driver == "facebook"
                    ? "facebook-f"
                    : "mail"
                }
                direction="top"
                text={`Sign up using ${customer.driver ?? "email"}`}
              />,

              <StatusBadge status={customer.status} />,
              [
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => setView(customer)}
                    >
                      <Icon name={"info"}></Icon>
                      <span>Info</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => setView(customer)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"customer_view_" + customer.id}
                        icon={"info"}
                        direction="top"
                        text={`Info`}
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() =>
                        onStatusClick(
                          customer.id,
                          customer.status === CustomerStatus.ACTIVE
                            ? CustomerStatus.DISABLED
                            : CustomerStatus.ACTIVE
                        )
                      }
                    >
                      <Icon
                        name={
                          customer.status === CustomerStatus.ACTIVE
                            ? "na"
                            : "check-circle-cut"
                        }
                      ></Icon>
                      <span>
                        Mark as{" "}
                        {customer.status === CustomerStatus.ACTIVE
                          ? capitalizeText(CustomerStatus.DISABLED)
                          : capitalizeText(CustomerStatus.ACTIVE)}
                      </span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() =>
                        onStatusClick(
                          customer.id,
                          customer.status === CustomerStatus.ACTIVE
                            ? CustomerStatus.DISABLED
                            : CustomerStatus.ACTIVE
                        )
                      }
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"change_status" + customer.id}
                        icon={
                          customer.status === CustomerStatus.ACTIVE
                            ? "na"
                            : "check-circle-cut"
                        }
                        direction="top"
                        text={`Mark as ${
                          customer.status === CustomerStatus.ACTIVE
                            ? capitalizeText(CustomerStatus.DISABLED)
                            : capitalizeText(CustomerStatus.ACTIVE)
                        }`}
                      />
                    </li>
                  ),
                },
              ],
            ];
          })}
          paginationData={paginationData}
          filterOptions={[
            {
              label: "sort by",
              name: "sort",
              options: [
                { value: "created_at-desc", label: "Latest" },
                { value: "orders_count-desc", label: "Orders" },
                { value: "first_name-asc", label: "Name" },
                { value: "status-asc", label: "Status" },
                { value: "driver-asc", label: "Sign In Method" },
              ],
            },
          ]}
        />
      </Content>
    </>
  );
};

export default CustomersIndex;
