import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../../../components/Component";
import { capitalizeText } from "../../../utils/methods";

const CustomMeasurementModal = ({ measurement, onClose }) => {
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <Modal
      isOpen={!!measurement}
      toggle={() => {
        onClose();
      }}
      fullscreen
      size="sm"
    >
      <ModalHeader
        toggle={() => {
          onClose();
        }}
        close={
          <button
            className="close"
            onClick={() => {
              onClose();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        Custom Measurement
      </ModalHeader>

      <ModalBody>
        {measurement &&
          Object.keys(measurement).map((customKey) => (
            <div
              key={customKey}
              className="d-flex align-items-center justify-content-between pb-2 "
            >
              <span>{capitalizeText(customKey)}:</span>
              <span>{measurement[customKey]}</span>
            </div>
          ))}
      </ModalBody>
    </Modal>
  );
};

export default CustomMeasurementModal;
