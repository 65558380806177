import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import {
  Button,
  Col,
  Icon,
  OverlineTitle,
} from "../../../../components/Component";
import Input from "../../../../components/input/input/input";
import Select from "../../../../components/input/select/select";
import useForm from "../../../../hooks/use-form";
import useRequest from "../../../../hooks/use-request";
import apiRoutes from "../../../../routes/api-routes";
import { addAlert } from "../../../../utils/alert";
import { shippingWeightColumns } from "../../../../utils/statics";
let inputFields = {
  standard: "",
  day_from: "",
  day_to: "",
  country_name: "",
};

shippingWeightColumns.forEach(({ column }) => {
  inputFields[column] = "";
});
// ---------------------------------------COMPONENT START---------------------------------------------
const CreatUpdateModal = ({
  show,
  onClose,
  country,
  carrierId,
  onSuccess,
  duplicateData,
}) => {
  const { isLoading, sendRequest, validationErros } = useRequest();
  const { inputs, setOne, setFields, reset } = useForm(inputFields);
  const [countryOptions, setCountryOptions] = useState([]);

  const getCountry = useCallback(async () => {
    const response = await sendRequest({
      url: apiRoutes.COUNTRIES_INDEX,
    });

    if (response) {
      setCountryOptions(
        response.map((c) => ({
          value: c.name,
          label: c.name,
        }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    getCountry();
  }, [getCountry]);
  useEffect(() => {
    if (country) {
      let fields = {
        country_name: country.country_name,
        day_from: country.day_from,
        day_to: country.day_to,
        standard: country.standard,
      };
      shippingWeightColumns.forEach(({ column }) => {
        fields[column] = country[column];
      });
      setFields(fields);
    }

    if (duplicateData?.country_name) {
      setFields((prev) => ({
        ...prev,
        ...duplicateData,
      }));
    }
  }, [country, duplicateData, setFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      let response = await sendRequest({
        url: country
          ? apiRoutes.CARRIER_COUNTRY_UPDATE(carrierId, country.id)
          : apiRoutes.CARRIER_COUNTRY_CREATE(carrierId),
        method: country ? "PATCH" : "POST",
        body: inputs,
      });

      if (response) {
        reset();
        addAlert(
          toast.TYPE.SUCCESS,
          `Country has been ${country ? "updated" : "added"} successfully`
        );
        onClose();
        onSuccess();
      }
    }
  };
  return (
    <Modal
      isOpen={show}
      toggle={() => {
        reset();
        onClose();
      }}
      fullscreen
      size="xl"
    >
      <ModalHeader
        toggle={() => {
          reset();
          onClose();
        }}
        close={
          <button
            className="close"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        {country ? "Update " : "Create "} Country Shipping
      </ModalHeader>
      <form onSubmit={onSubmit}>
        <ModalBody>
          <Row className="g-3">
            <Select
              options={countryOptions}
              columnConfig={{ sm: 6 }}
              label="country"
              set={setOne}
              name="country_name"
              model={inputs.country_name}
              error={validationErros?.country_name}
            />
            <Input
              columnConfig={{ sm: 6 }}
              label="Standard"
              set={setOne}
              name="standard"
              model={inputs.standard}
              error={validationErros?.standard}
            />
            <OverlineTitle>Delivery Time</OverlineTitle>
            <Input
              columnConfig={{ sm: 6 }}
              set={setOne}
              placeholder="From"
              type="number"
              name="day_from"
              model={inputs.day_from}
              error={validationErros?.day_from}
            />

            <Input
              columnConfig={{ sm: 6 }}
              set={setOne}
              placeholder="To"
              type="number"
              name="day_to"
              model={inputs.day_to}
              error={validationErros?.day_to}
            />
            <OverlineTitle>Shipping Cost (QAR)</OverlineTitle>
            {shippingWeightColumns.map(({ column, label }) => (
              <Input
                columnConfig={{ sm: 4, md: 3, lg: 3 }}
                set={setOne}
                placeholder={label}
                name={column}
                model={inputs[column]}
                error={validationErros && validationErros[column]}
              />
            ))}
          </Row>
        </ModalBody>
        <ModalFooter className="bg-light">
          <Button isLoading={isLoading}>{country ? "Update" : "Create"}</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreatUpdateModal;
