import _ from "lodash";

// export const imageUrl = (uri) => {
//   return "http://www.softmaster.local/storage/" + uri;
// };

export const capitalizeText = (text) => {
  if (!text) {
    return text;
  }
  let trimText = text.replaceAll("_", " ").replaceAll("-", " ");
  return _.startCase(trimText.toLowerCase());
};

export const imageKitLoader = (src, width, height = null, quality = null) => {
  if (!src) {
    return null;
  }
  if (src[0] === "/") src = src.slice(1);
  src = encodeURIComponent(src);
  const params = [`w-${width}`];

  if (height) {
    params.push(`h-${height}`);
  }
  if (quality) {
    params.push(`q-${quality}`);
  }
  const paramsString = params.join(",");
  var urlEndpoint = process.env.REACT_APP_IMAGE_KIT_LINK;
  if (urlEndpoint[urlEndpoint.length - 1] === "/")
    urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1);
  return `${urlEndpoint}/${src}?alt=media&tr=${paramsString}`;
};

export const scrollTop = (top = 0, behavior = "smooth") => {
  document.documentElement.scrollTo({ top, behavior });
};
// export const currency = (amount) => {
//   return `$${amount}`;
// };
// export const priceRangeHtml = ({
//   min_standard_price,
//   min_current_price,
//   max_standard_price,
//   max_current_price,
// }) => {
//   let content = "";
//   // if (min_current_price < min_standard_price) {
//   //   content =
//   //     content +
//   //     ` <span class="cut-price">${currency(min_standard_price)}</span>`;
//   // }
//   content = content + `${currency(min_current_price)}`;
//   // if (max_current_price < max_standard_price) {
//   //   content =
//   //     content +
//   //     ` <span class="cut-price">${currency(max_standard_price)}</span>`;
//   // }

//   if (min_current_price !== max_current_price) {
//     content = content + ` - ${currency(max_current_price)}`;
//   }
//   return content;
// };

// export const priceHtml = (combination) => {
//   let content = `${currency(combination.current_price)}`;
//   if (combination.current_price < combination.standard_price) {
//     content =
//       content +
//       ` <span class="cut-price">${currency(combination.standard_price)}</span>`;
//   }
//   return content;
// };

export const getVariants = (...args) => {
  var r = [],
    max = args.length - 1;
  function helper(arr, i) {
    for (var j = 0, l = args[i].length; j < l; j++) {
      var a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i == max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
};

// export const merge_formdata = (data1, data2) => {
//   for (var pair of data2.entries()) {
//     data1.append(pair[0], pair[1]);
//   }
//   return data1;
// };

// export const shippingCalculation = (shipping, product, quantity) => {
//   if (shipping && product.is_free_shipping) {
//     return 0;
//   } else if (shipping) {
//     let totalWeight = product.unit_weight * quantity;

//     if (totalWeight <= 1000) {
//       return shipping.first_1kg;
//     } else {
//       return (
//         shipping.first_1kg +
//         shipping.add_1kg * Math.ceil((totalWeight - 1000) / 1000)
//       );
//     }
//   }
//   return null;
// };

export const searchParamsToObject = (searchParams) => {
  let object = {};
  for (const [key, value] of searchParams.entries()) {
    object[key] = value;
  }
  return object;
};

export const removeQueryParam = (key, queryParams) => {
  let searchParams = new URLSearchParams(queryParams.replace("?", ""));
  searchParams.delete(key);
  return searchParams.toString();
};

export const round2Decimal = (a) => {
  var pow = Math.pow(10, 2);
  return Math.round(a * pow) / pow;
};

export const paypalRefundDetails = (orderProduct) => {
  //for now only for paypal refund
  let data = orderProduct?.order?.payment.data
    ? JSON.parse(orderProduct?.order?.payment.data)
    : null;
  let payment =
    data?.purchase_units[0]?.payments.captures[0]?.seller_receivable_breakdown;

  // fee rate per one percentage
  let feeRate =
    parseFloat(payment?.paypal_fee.value) /
    parseFloat(payment?.gross_amount.value);

  // total order product paid amount (QAR)
  let totalPayable =
    parseFloat(orderProduct.shipping) + parseFloat(orderProduct.subtotal);

  // total order product paid amount (USD)
  // let totalPayableUsd =
  //   (totalPayable / parseFloat(orderProduct.order.payable_amount)) *
  //   parseFloat(orderProduct.order.payment.amount);

  // Paypal fee only for this product in the order
  let productFee = totalPayable * feeRate;
  let refundAmount = round2Decimal(totalPayable - productFee);

  return [
    {
      label: "Paid Amount (QAR)",
      value: totalPayable,
    },
    // {
    //   label: "Paid Amount (USD)",
    //   value: totalPayableUsd,
    // },
    {
      label: "Fee (QAR)",
      value: productFee,
    },
    {
      label: "Refund Amount approx. (QAR)",
      value: refundAmount,
    },
  ];
};

export const myFatoorahRefundDetails = (orderProduct) => {
  //for now only for paypal refund
  let data = orderProduct?.order?.payment.data
    ? JSON.parse(orderProduct?.order?.payment.data)
    : null;
  let receivable = parseFloat(data.Data.DueDeposit);
  let fullPayment = parseFloat(data.Data.InvoiceValue);

  // fee rate per one percentage
  let feeRate = (fullPayment - receivable) / fullPayment;

  // total order product paid amount (QAR)
  let totalPayable =
    parseFloat(orderProduct.shipping) + parseFloat(orderProduct.subtotal);

  // fee only for this product in the order
  let productFee = totalPayable * feeRate;
  let refundAmount = round2Decimal(totalPayable - productFee);

  return [
    {
      label: "Paid Amount (QAR)",
      value: totalPayable,
    },
    {
      label: "Service Charge + VAT (-)",
      value: productFee,
    },
    {
      label: "Refund Amount (QAR)",
      value: refundAmount,
    },
  ];
};
