import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Card, Col, Form, Row } from "reactstrap";
import HeadSection from "../../components/admin-page-components/HeadSection";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../components/Component";
import ImageInput from "../../components/input/image/image";
import Input from "../../components/input/input/input";
import PhoneNumberInput from "../../components/input/phone-number-input/phone-number-input";
import Select from "../../components/input/select/select";
import Overlay from "../../components/loaders/page-overlay";
import PageOverlay from "../../components/loaders/page-overlay";
import useForm from "../../hooks/use-form";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import apiRoutes from "../../routes/api-routes";
import routes from "../../routes/routes-data";
import { addAlert } from "../../utils/alert";

const AdminsCreateEdit = (props) => {
  const [passState, setPassState] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  const [isImageChanged, setIsImageChanged] = useState(false);
  const [adminFecthed, setAdminFetched] = useState(false);

  const { isLoading, sendRequest, sendFormRequest, validationErros } =
    useRequest();
  const navigate = useNavigate();
  const { adminId } = useParams();

  const { setOne, inputs, setFields } = useForm({
    email: "",
    password: "",
    password_confirmation: "",
    name: "",
    username: "",
    phone_number: "",
    country_name: "",
    profile_pic: null,
    roles: [],
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("email", inputs.email);
    data.append("password", inputs.password);
    data.append("password_confirmation", inputs.password_confirmation);
    data.append("name", inputs.name);
    data.append("username", inputs.username);
    data.append("phone_number", inputs.phone_number);
    data.append("country_name", inputs.country_name);

    inputs.roles?.forEach((role, index) => {
      data.append(`roles[${index}]`, role.value);
    });

    data.append("profile_pic", inputs.profile_pic?.file ?? "");

    if (adminId) {
      data.append("_method", "PATCH");
      data.append("image_changed", isImageChanged ? 1 : 0);
    }
    if (!isLoading) {
      const response = await sendFormRequest({
        url: adminId ? apiRoutes.ADMIN_UPDATE(adminId) : apiRoutes.ADMIN_CREATE,
        body: data,
      });
      if (response) {
        addAlert(
          toast.TYPE.SUCCESS,
          adminId
            ? "Admin Details updated successfully"
            : "Admin created successfully"
        );
        navigate(routes.ADMIN_INDEX);
      }
    }
  };
  const getOptions = useCallback(async () => {
    const response = await sendRequest({
      url: apiRoutes.ADMIN_OPTIONS,
    });

    if (response) {
      setCountries(response.countries);
      setCountryOptions(
        response.countries.map((c) => ({
          value: c.name,
          label: `${c.name} (+${c.phone_code})`,
        }))
      );
      setRoleOptions(
        response.roles?.map((r) => ({
          value: r.id,
          label: r.name.replaceAll("_", " ").toLowerCase(),
        }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  useEffect(() => {
    const getAdmin = async () => {
      let response = await sendRequest({
        url: apiRoutes.ADMIN_SHOW(adminId),
      });

      if (response?.admin) {
        let admin = response.admin;
        setFields({
          email: admin.email,
          password: "",
          password_confirmation: "",
          name: admin.name,
          username: admin.username,
          phone_number: admin.phone_number,
          country_name: admin.country_name,
          roles: admin.admin_roles.map((role) => ({
            value: role.id,
            label: role.name.replaceAll("_", " ").toLowerCase(),
          })),
          profile_pic: admin.profile_pic
            ? {
                id: admin.profile_pic?.id,
                file: null,
                url: admin.profile_pic?.access_path,
              }
            : null,
        });
      }

      setAdminFetched(true);
    };
    if (adminId) {
      getAdmin();
    }
  }, [adminId, sendRequest, setFields]);
  return (
    <Content>
      <HeadSection
        right={
          <Breadcrumb className="breadcrumb-arrow">
            <BreadcrumbItem>
              <Link to={routes.ADMIN_INDEX}>Admins</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {adminId ? "Update" : "Create"}
            </BreadcrumbItem>
          </Breadcrumb>
        }
        left={
          <BlockTitle page>{adminId ? "Update" : "Create"} Admin</BlockTitle>
        }
      />

      <Card className="p-4">
        {adminId && !adminFecthed ? (
          <Overlay spinner bg />
        ) : (
          <Form onSubmit={onFormSubmit}>
            <Row className="g-4">
              <ImageInput
                columnConfig={{ sm: 12 }}
                label="Profile Pic (Optional)"
                set={(key, value) => {
                  setIsImageChanged(true);
                  setOne(key, value);
                }}
                name="profile_pic"
                model={inputs.profile_pic}
                type="circle"
                error={validationErros?.profile_pic}
              />

              <Input
                columnConfig={{ sm: 12 }}
                label="full name"
                set={setOne}
                name="name"
                model={inputs.name}
                error={validationErros?.name}
              />
              <PhoneNumberInput
                columnConfig={{ sm: 6 }}
                country={countries?.find((c) => c.name == inputs.country_name)}
                label="phone number"
                set={setOne}
                name="phone_number"
                model={inputs.phone_number}
                error={validationErros?.phone_number}
              />
              <Select
                options={countryOptions}
                columnConfig={{ sm: 6 }}
                label="country"
                set={setOne}
                name="country_name"
                model={inputs.country_name}
                error={validationErros?.country_name}
              />
              <Input
                columnConfig={{ sm: 6 }}
                label="email"
                set={setOne}
                name="email"
                model={inputs.email}
                error={validationErros?.email}
              />
              <Input
                columnConfig={{ sm: 6 }}
                label="username"
                set={setOne}
                name="username"
                model={inputs.username}
                error={validationErros?.username}
              />
              <Select
                multiple
                options={roleOptions}
                columnConfig={{ sm: 12 }}
                label="access"
                set={setOne}
                name="roles"
                model={inputs.roles}
                error={validationErros?.roles}
              />
              <Input
                columnConfig={{ sm: 6 }}
                name="password"
                set={setOne}
                model={inputs.password}
                error={validationErros?.password}
                label={adminId ? "New Password" : "password"}
                type={passState ? "text" : "password"}
                rightSide={
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon form-icon-right passcode-switch ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                }
              />
              <Input
                columnConfig={{ sm: 6 }}
                name="password_confirmation"
                set={setOne}
                model={inputs.password_confirmation}
                error={validationErros?.password}
                label={
                  adminId
                    ? "new password confirmation"
                    : "password confirmation"
                }
                type={passState ? "text" : "password"}
              />
              <Col sm={12} className=" mt-3">
                <Button
                  isLoading={isLoading}
                  size="lg"
                  className={"m-auto d-block"}
                  type="submit"
                  color="primary"
                >
                  {adminId ? "Update" : "Create"}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </Content>
  );
};

export default AdminsCreateEdit;
