import { useSelector } from "react-redux";
import apiRoutes from "../../routes/api-routes";
import { AxiosRequest } from "../../utils/request";

const Currency = ({ amount, code = "QAR" }) => {
  // const active_country = useSelector((store) => store.user?.active_country);
  try {
    if (isNaN(amount)) {
      return amount;
    } else {
      return `${parseFloat(amount).toFixed(2)}`;
    }
    //   if (active_country.exchange_rate != 0.0) {
    //     return `${active_country.currency_code} ${parseFloat(
    //       amount * active_country.exchange_rate
    //     ).toFixed(2)}`;
    //   } else {
    //     return `QAR ${parseFloat(amount).toFixed(2)}`;
    //   }
  } catch (error) {
    new AxiosRequest().sendRequest({
      url: apiRoutes.ERROR_STORE,
      method: "POST",
      body: {
        message: error.message,
        trace: error.stack,
      },
    });
    return amount;
  }
};

export default Currency;
