import { useEffect } from "react";
import { toast } from "react-toastify";
import { Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  BlockDes,
  Row,
  Button,
} from "../../../components/Component";
import Input from "../../../components/input/input/input";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import { addAlert } from "../../../utils/alert";
const CreatUpdateModal = ({ show, onClose, carrier, onSuccess }) => {
  const { isLoading, sendRequest, validationErros } = useRequest();
  const { inputs, setOne, setFields, reset } = useForm({
    name: "",
  });

  useEffect(() => {
    if (carrier) {
      setFields({
        name: carrier.name,
      });
    }
  }, [carrier, setFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      let response = await sendRequest({
        url: carrier
          ? apiRoutes.CARRIER_UPDATE(carrier.id)
          : apiRoutes.CARRIER_CREATE,
        method: carrier ? "PATCH" : "POST",
        body: inputs,
      });

      if (response) {
        reset();
        addAlert(
          toast.TYPE.SUCCESS,
          `Carrier has been ${carrier ? "updated" : "added"} successfully`
        );
        onClose();
        onSuccess();
      }
    }
  };
  return (
    <>
      <SimpleBar
        className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${
          show ? "content-active" : ""
        }`}
      >
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5">{carrier ? "Edit" : "Add"} Carrier</BlockTitle>
            <BlockDes>
              <p>Add information and create carrier</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <form onSubmit={onSubmit}>
            <Row className="g-3">
              <Input
                columnConfig={{ sm: 12 }}
                label="name"
                set={setOne}
                name="name"
                model={inputs.name}
                error={validationErros?.name}
              />
              <Col>
                <Button isLoading={isLoading}>
                  {carrier ? "Update" : "Create"}
                </Button>
              </Col>
            </Row>
          </form>
        </Block>
      </SimpleBar>
      {show && (
        <div
          className="toggle-overlay"
          onClick={() => {
            reset();
            onClose();
          }}
        ></div>
      )}
    </>
  );
};

export default CreatUpdateModal;
