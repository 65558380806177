import { Fragment, useEffect } from "react";
import { Col, FormGroup } from "reactstrap";
import { default as RSelect } from "react-select";

const Select = ({
  options,
  columnConfig,
  label,
  error,
  set,
  model,
  name,
  multiple,
  group,
  ...props
}) => {
  let Wrapper = columnConfig ? Col : Fragment;

  return (
    <Wrapper {...columnConfig}>
      <FormGroup>
        {label && (
          <div className="form-label-group">
            <label className=" form-label text-capitalize" htmlFor="default-01">
              {label}
            </label>
          </div>
        )}
        <div
          className="form-control-select
        "
        >
          <RSelect
            isMulti={multiple ?? false}
            options={options}
            onChange={(e) => {
              if (multiple || group) {
                set(name, e);
              } else {
                set(name, e.value);
              }
            }}
            value={
              multiple || group
                ? model
                : model != "" && options.find((op) => op.value == model)
            }
            placeholder={`Select your ${label}`}
            className={`react-select-container  ${
              props.className ? props.className : ""
            }`}
            classNamePrefix="react-select"
            // classNames={{
            //   control: (state) => (error ? "error" : ""),
            // }}

            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                textTransform: "capitalize",
                borderColor: error && "#e85347",
                "&:hover": {
                  borderColor: error && "#e85347",
                },
                "&:focus": {
                  borderColor: error && "#e85347",
                },
              }),
              option: (
                styles,
                { data, isDisabled, isFocused, isSelected }
              ) => ({
                ...styles,
                textTransform: "capitalize",
              }),
            }}
            {...props}
          />
          {error && <span className="invalid">{error}</span>}
        </div>
      </FormGroup>
    </Wrapper>
  );
};

export default Select;
