import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Row, Col, CardTitle } from "reactstrap";
import {
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Button,
} from "../../../components/Component";
import Image from "../../../components/image/Image";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import routes from "../../../routes/routes-data";
import { addAlert } from "../../../utils/alert";
import { ProductOptionTypes } from "../../../utils/statics";

const PriceList = ({
  stepState: { hasNext, next, hasPrev, prev },
  onUnmount,
  productId,
  fields,
  isOptionUpdated,
  setIsOptionUpdated,
  ...props
}) => {
  const { isLoading, validationErros, sendFormRequest } = useRequest();
  const { inputs, setOne, setFields } = useForm({});

  const navigate = useNavigate();
  const onFormSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    // BASIC INFO
    data.append("name", inputs.name);
    data.append("description", inputs.description);
    data.append("size_guide_id", inputs.size_guide_id ?? "");
    data.append("is_featured", inputs.is_featured ? 1 : 0);

    // CATEGORIES
    inputs.categories?.forEach((cat, index) => {
      data.append(`categories[${index}]`, cat.value);
    });

    // IMAGES
    inputs.images
      ?.filter((img) => !img.is_existing)
      ?.forEach((image, index) => {
        if (image?.file) {
          data.append(`images[${index}]`, image.file);
        }
      });

    // CARRIERS
    inputs.carriers?.forEach((carrier, index) => {
      data.append(`carriers[${index}]`, carrier.value);
    });

    // OPTIONS
    inputs.options.forEach((opt, index) => {
      data.append(`options[${index}][name]`, opt.name);
      data.append(`options[${index}][type]`, opt.type);
      if (opt.is_existing) {
        data.append(`options[${index}][id]`, opt.id);
      }

      opt.values.forEach((val, i) => {
        data.append(`options[${index}][values][${i}][value]`, val.value);
        data.append(`options[${index}][values][${i}][color]`, val.color);
        if (val.is_existing) {
          data.append(`options[${index}][values][${i}][id]`, val.id);
        }
      });
    });

    // VARIANTS
    inputs.variants.forEach((variant, index) => {
      if (variant.is_existing) {
        data.append(`variants[${index}][id]`, variant.id);
      }
      data.append(`variants[${index}][stock]`, variant.stock);
      data.append(`variants[${index}][current_price]`, variant.current_price);
      data.append(`variants[${index}][standard_price]`, variant.standard_price);
      data.append(`variants[${index}][unit_weight]`, variant.unit_weight);
      data.append(`variants[${index}][image_index]`, variant.image_index ?? "");
    });

    // ON UPDATE:...
    if (productId) {
      data.append("_method", "PATCH");

      // ON UPDATE: OPTION UPDATED STATUS
      data.append("is_option_updated", isOptionUpdated ? 1 : 0);

      // ON UPDATE: EXISTING IMAGES ID
      inputs.images
        ?.filter((img) => img.is_existing)
        ?.forEach((img, index) => {
          data.append(`existing_images[${index}]`, img.id);
        });
    }

    if (!isLoading) {
      let response = await sendFormRequest({
        url: productId
          ? apiRoutes.PRODUCT_UPDATE(productId)
          : apiRoutes.PRODUCT_CREATE,
        body: data,
      });
      if (response) {
        addAlert(
          toast.TYPE.SUCCESS,
          `Product has been ${productId ? "updated" : "added"} successfully`
        );
        navigate(routes.PRODUCT_INDEX);
      }
    }
  };

  useEffect(() => {
    setFields(fields);
  }, [fields, setFields]);

  return (
    <div className="">
      <Form onSubmit={onFormSubmit}>
        <Row className="g-4">
          <Col sm={12}>
            <table className="table table-ulogs ">
              <thead className="thead-light">
                {inputs.options?.map((option, index) => (
                  <th
                    className="tb-col-ip"
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: index === 0 ? "8px" : "0",
                    }}
                  >
                    <span className="overline-title">{option.name}</span>
                  </th>
                ))}

                <th className="tb-col-ip" style={{ verticalAlign: "middle" }}>
                  <span className="overline-title">Standard Price</span>
                </th>
                <th className="tb-col-ip" style={{ verticalAlign: "middle" }}>
                  <span className="overline-title">Current Price</span>
                </th>
                <th className="tb-col-ip" style={{ verticalAlign: "middle" }}>
                  <span className="overline-title">Unit Weight</span>
                </th>
                <th className="tb-col-ip" style={{ verticalAlign: "middle" }}>
                  <span className="overline-title">Stock</span>
                </th>
                <th className="tb-col-ip" style={{ verticalAlign: "middle" }}>
                  <span className="overline-title">Image</span>
                </th>
              </thead>
              <tbody>
                {inputs.variants?.map((variant, index) => {
                  return (
                    <tr key={index}>
                      {variant.option_values?.map((optVal, index) => (
                        <td
                          className="tb-col-ip"
                          style={{
                            paddingLeft: index === 0 ? "8px" : "0",
                          }}
                        >
                          {optVal[0]}
                        </td>
                      ))}
                      <td className="tb-col-ip" style={{ paddingLeft: 0 }}>
                        <Input
                          set={setOne}
                          placeholder="(QAR)"
                          name={`variants[${index}].standard_price`}
                          model={inputs.variants[index].standard_price}
                          error={
                            validationErros?.[
                              `variants.${index}.standard_price`
                            ]
                          }
                        />
                      </td>
                      <td className="tb-col-ip" style={{ paddingLeft: 0 }}>
                        <Input
                          set={setOne}
                          placeholder="(QAR)"
                          name={`variants[${index}].current_price`}
                          model={inputs.variants[index].current_price}
                          error={
                            validationErros?.[`variants.${index}.current_price`]
                          }
                        />
                      </td>
                      <td className="tb-col-ip" style={{ paddingLeft: 0 }}>
                        <Input
                          set={setOne}
                          placeholder="(g)"
                          name={`variants[${index}].unit_weight`}
                          model={inputs.variants[index].unit_weight}
                          error={
                            validationErros?.[`variants.${index}.unit_weight`]
                          }
                        />
                      </td>
                      <td className="tb-col-ip" style={{ paddingLeft: 0 }}>
                        <Input
                          set={setOne}
                          placeholder=""
                          name={`variants[${index}].stock`}
                          model={inputs.variants[index].stock}
                          error={validationErros?.[`variants.${index}.stock`]}
                        />
                      </td>
                      <td className="tb-col-ip" style={{ paddingLeft: 0 }}>
                        <Select
                          options={[
                            {
                              label: "None",
                              value: "",
                            },
                            ...inputs.images?.map((image, index) => ({
                              label: (
                                <Image
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    objectFit: "cover",
                                  }}
                                  src={image.url}
                                  alt="product"
                                />
                              ),
                              value: index + 1,
                            })),
                          ]}
                          set={setOne}
                          placeholder=""
                          name={`variants[${index}].image_index`}
                          model={inputs.variants[index].image_index}
                          error={
                            validationErros?.[`variants.${index}.image_index`]
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>

          <Col sm={12} className=" mt-3">
            <BlockBetween>
              <BlockHeadContent>
                {hasPrev && (
                  <Button
                    onClick={() => {
                      onUnmount();
                      prev();
                    }}
                    isLoading={isLoading}
                    size="lg"
                    className={"m-auto d-block btn-dim"}
                    type="button"
                    color="light"
                  >
                    Previous
                  </Button>
                )}
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  isLoading={isLoading}
                  size="lg"
                  className={"m-auto d-block"}
                  type="submit"
                  color="primary"
                >
                  {productId ? "Update" : "Create"}
                </Button>
              </BlockHeadContent>
            </BlockBetween>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PriceList;
