import React from "react";
import Head from "../../layouts/dashboard/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  TooltipComponent,
} from "../../components/Component";
import useRequest from "../../hooks/use-request";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import apiRoutes from "../../routes/api-routes";
import moment from "moment";
import Overlay from "../../components/loaders/page-overlay";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import { capitalizeText, searchParamsToObject } from "../../utils/methods";
import Swal from "sweetalert2";
import PageTable from "../../components/admin-page-components/PageTable";
import CreatUpdateModal from "./partials/create-update-modal";
import routes from "../../routes/routes-data";
import { DropdownItem } from "reactstrap";

const NotificationConfigurations = () => {
  const [sm, updateSm] = useOutletContext();

  const [showModal, setShowModal] = useState(false);
  const [
    selectedNotificationConfiguration,
    setSelectedNotificationConfiguration,
  ] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [notificationConfigurations, setNotificationConfigurations] = useState(
    []
  );
  const { isLoading, sendRequest, error } = useRequest();

  const getNotificationConfigurations = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.NOTIFICATION_CONFIGURATION_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response?.data) {
      setNotificationConfigurations(
        response.data?.length > 0 ? response.data : []
      );
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.NOTIFICATION_CONFIGURATION_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        addAlert(
          toast.TYPE.SUCCESS,
          "NotificationConfiguration has been deleted successfully"
        );
        getNotificationConfigurations();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Corresponding country shipping details also will be deleted. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };
  useEffect(() => {
    getNotificationConfigurations();
  }, [getNotificationConfigurations, searchParams]);
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Notification Emails</BlockTitle>
            <BlockDes>
              <p>
                Here you can config the email address & phone number which need
                to be notify on new order{" "}
                <span className="text-soft">
                  <Icon name="info" />
                </span>
              </p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              color={""}
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <PageTable
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        searchPlaceholder={"Search by email, phone number"}
        leftComponent=<Button
          className="d-inline-flex"
          color="primary"
          onClick={() => setShowModal(true)}
        >
          <Icon name="plus"></Icon>
        </Button>
        isLoading={isLoading}
        error={error}
        headers={["contact"]}
        actions={true}
        cardClass="shadow-none"
        body={notificationConfigurations?.map((notificationConfiguration) => {
          return [
            <div>
              {notificationConfiguration.email && (
                <p>
                  <strong>Email:</strong> {notificationConfiguration.email}
                </p>
              )}
              {notificationConfiguration.phone_number && (
                <p>
                  <strong>Phone:</strong>{" "}
                  {notificationConfiguration.phone_number}
                </p>
              )}
            </div>,
            [
              {
                dropdown: (
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={() => {
                      setSelectedNotificationConfiguration(
                        notificationConfiguration
                      );
                      setShowModal(true);
                    }}
                  >
                    <Icon name="edit"></Icon>
                    <span>Edit</span>
                  </DropdownItem>
                ),
                tooltip: (
                  <li
                    className="nk-tb-action-hidden"
                    onClick={() => {
                      setSelectedNotificationConfiguration(
                        notificationConfiguration
                      );
                      setShowModal(true);
                    }}
                  >
                    <TooltipComponent
                      tag="a"
                      containerClassName="btn btn-trigger btn-icon"
                      id={"edit" + notificationConfiguration.id}
                      icon="edit"
                      direction="top"
                      text="Edit"
                    />
                  </li>
                ),
              },
              {
                dropdown: (
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={() => onDeleteClick(notificationConfiguration.id)}
                  >
                    <Icon name="trash-empty"></Icon>
                    <span>Delete</span>
                  </DropdownItem>
                ),
                tooltip: (
                  <li
                    className="nk-tb-action-hidden"
                    onClick={() => onDeleteClick(notificationConfiguration.id)}
                  >
                    <TooltipComponent
                      tag="a"
                      containerClassName="btn btn-trigger btn-icon"
                      id={"delete" + notificationConfiguration.id}
                      icon="trash-empty"
                      direction="top"
                      text="Delete"
                    />
                  </li>
                ),
              },
            ],
          ];
        })}
        paginationData={paginationData}
      />
      <CreatUpdateModal
        notificationConfiguration={selectedNotificationConfiguration}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedNotificationConfiguration(null);
        }}
        onSuccess={() => getNotificationConfigurations()}
      />
    </React.Fragment>
  );
};
export default NotificationConfigurations;
