import { Line } from "react-chartjs-2";
import { Card } from "reactstrap";
import { Icon } from "../../../components/Component";

const DataCard = ({ title, total, diff, status, icon, theme }) => {
  // diff: 0;
  // lastMonth: 0;
  // status: "up";
  // thisMonth: 0;
  // total: 12;
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <Card>
      <div className="nk-ecwg nk-ecwg6">
        <div className="card-inner flex-between align-items-start">
          <div>
            <div className="card-title-group">
              <div className="card-title">
                <h6 className="title">{title}</h6>
              </div>
            </div>
            <div className="data">
              <div className="data-group">
                <div className="amount">{total}</div>
                {/* <div className="nk-ecwg6-ck">{ChartComponent}</div> */}
              </div>
              <div className="info">
                <span
                  className={`change ${
                    status == "up" ? "up text-success" : "down text-danger"
                  }`}
                >
                  <Icon name={`arrow-long-${status}`}></Icon>
                  {diff}
                </span>
                <span> vs. last month</span>
              </div>
            </div>
          </div>
          <div
            className="flex  p-1"
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: "100px",
              aspectRatio: "1/0.75",
              background: `${theme}61`,
              borderRadius: "10px",
            }}
          >
            <Icon name={icon} style={{ fontSize: "50px", color: theme }} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DataCard;
