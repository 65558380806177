import { useEffect, useState } from "react";
import _ from "lodash";
const useForm = (inputs) => {
  const [fields, setFields] = useState({});
  useEffect(() => {
    setFields(inputs);
  }, []);

  const setOne = (key, value) => {
    var schema = { ...fields };
    _.set(schema, key, value);
    setFields(schema);
  };
  const reset = () => {
    let empty = {};
    Object.keys(fields).forEach((key) => {
      empty[key] = Array.isArray(fields[key]) ? [] : "";
    });

    setFields(empty);
  };
  return {
    inputs: fields,
    setOne,
    setFields,
    reset,
  };
};

export default useForm;
