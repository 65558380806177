import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import { searchParamsToObject } from "../../utils/methods";
import CreatUpdateModal from "./partials/create-update-modal";
import HeadSection from "../../components/admin-page-components/HeadSection";
import Button from "../../components/button/Button";
import Swal from "sweetalert2";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import routes from "../../routes/routes-data";

const CarriersIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [carriers, setCarriers] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();

  const getCarriers = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.CARRIER_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response?.data) {
      setCarriers(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.CARRIER_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Carrier has been deleted successfully");
        getCarriers();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Corresponding country shipping details also will be deleted. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };
  useEffect(() => {
    getCarriers();
  }, [getCarriers, searchParams]);

  return (
    <>
      <Head title="Carriers"></Head>
      <Content>
        <HeadSection
          title={"Carriers"}
          right={
            <Button
              className="d-inline-flex"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              <Icon name="plus"></Icon>
              <span className="d-none d-md-block">Add Carrier</span>
            </Button>
          }
        />
        <PageTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchPlaceholder={"Search by carrier name, id"}
          title="All Carriers"
          isLoading={isLoading}
          error={error}
          headers={["id", "name"]}
          actions={true}
          body={carriers?.map((carrier) => {
            return [
              carrier.id,
              <span className="text-capitalize">{carrier.name}</span>,
              [
                {
                  dropdown: (
                    <DropdownItem
                      tag={Link}
                      to={routes.CARRIER_COUNTRIES_INDEX(carrier.id)}
                    >
                      <Icon name="globe"></Icon>
                      <span>Countries</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        navigate(routes.CARRIER_COUNTRIES_INDEX(carrier.id));
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"view" + carrier.id}
                        icon="globe"
                        direction="top"
                        text="View Countries"
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => {
                        setSelectedCarrier(carrier);
                        setShowModal(true);
                      }}
                    >
                      <Icon name="edit"></Icon>
                      <span>Edit</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        setSelectedCarrier(carrier);
                        setShowModal(true);
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"edit" + carrier.id}
                        icon="edit"
                        direction="top"
                        text="Edit Carrier"
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => onDeleteClick(carrier.id)}
                    >
                      <Icon name="trash-empty"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteClick(carrier.id)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"delete" + carrier.id}
                        icon="trash-empty"
                        direction="top"
                        text="Delete Carrier"
                      />
                    </li>
                  ),
                },
              ],
            ];
          })}
          paginationData={paginationData}
          // filterOptions={[
          //   {
          //     label: "sort by",
          //     name: "sort",
          //     options: [
          //       { value: "any s", label: "Any" },
          //       { value: "ZA", label: "Z-A" },
          //       { value: "AZ", label: "A-Z" },
          //       { value: "ZA", label: "Z-A" },
          //     ],
          //   },
          //   {
          //     label: "order by",
          //     name: "order",
          //     options: [
          //       { value: "any o", label: "Any" },
          //       { value: "ZA", label: "Z-A" },
          //       { value: "AZ", label: "A-Z" },
          //       { value: "ZA", label: "Z-A" },
          //     ],
          //   },
          // ]}
        />
      </Content>
      <CreatUpdateModal
        carrier={selectedCarrier}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedCarrier(null);
        }}
        onSuccess={() => getCarriers()}
      />
    </>
  );
};

export default CarriersIndex;
