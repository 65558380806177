import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, DropdownItem, Tooltip } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import { searchParamsToObject } from "../../utils/methods";
import CreatUpdateModal from "./partials/create-update-modal";
import HeadSection from "../../components/admin-page-components/HeadSection";
import Button from "../../components/button/Button";
import Swal from "sweetalert2";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import routes from "../../routes/routes-data";
import { useSelector } from "react-redux";
import Image from "../../components/image/Image";

const SizeGuidesIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSizeGuide, setSelectedSizeGuide] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [sizeGuides, setSizeGuides] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();

  let location = useLocation();

  const getSizeGuides = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.SIZE_GUIDE_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response) {
      setSizeGuides(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.SIZE_GUIDE_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        addAlert(
          toast.TYPE.SUCCESS,
          "Size guide has been deleted successfully"
        );
        getSizeGuides();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  useEffect(() => {
    setSizeGuides([]);
  }, [location.pathname]);

  useEffect(() => {
    getSizeGuides();
  }, [getSizeGuides, searchParams]);

  return (
    <>
      <Head title="SizeGuides"></Head>
      <Content>
        <HeadSection
          title={"Size Guides"}
          right={
            <Button
              className="d-inline-flex"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              <Icon name="plus"></Icon>
              <span className="d-none d-md-block">Add Size Guide</span>
            </Button>
          }
        />
        {/* ? = SUB CATEGORIES PAGE VIEW | : = MAIN CATEGORIES PAGE VIEW  */}

        {
          <PageTable
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            searchPlaceholder={"Search by size guide name, id"}
            title={"Main Size Guides"}
            isLoading={isLoading}
            error={error}
            headers={["name"]}
            actions={true}
            body={sizeGuides?.map((sizeGuide) => [
              <span className="tb-product">
                <Image
                  src={sizeGuide.image?.access_path}
                  alt="product"
                  className="thumb"
                  style={{
                    width: "100px",
                    height: "60px",
                    objectFit: "cover",
                  }}
                />
                <span className="title text-capitalize">{sizeGuide.name}</span>
              </span>,
              [
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => {
                        setSelectedSizeGuide(sizeGuide);
                        setShowModal(true);
                      }}
                    >
                      <Icon name="edit"></Icon>
                      <span>Edit</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        setSelectedSizeGuide(sizeGuide);
                        setShowModal(true);
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"edit" + sizeGuide.id}
                        icon="edit"
                        direction="top"
                        text="Edit SizeGuide"
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => onDeleteClick(sizeGuide.id)}
                    >
                      <Icon name="trash-empty"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteClick(sizeGuide.id)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"delete" + sizeGuide.id}
                        icon="trash-empty"
                        direction="top"
                        text="Delete SizeGuide"
                      />
                    </li>
                  ),
                },
              ],
            ])}
            paginationData={paginationData}
          />
        }
      </Content>
      <CreatUpdateModal
        sizeGuide={selectedSizeGuide}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedSizeGuide(null);
        }}
        onSuccess={() => getSizeGuides()}
      />
    </>
  );
};

export default SizeGuidesIndex;
