import _ from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import PageTable from "../../../components/admin-page-components/PageTable";
import PageTableMin from "../../../components/admin-page-components/PageTable-Min";
import { Icon, TooltipComponent } from "../../../components/Component";
import Currency from "../../../components/html/Currency";
import Image from "../../../components/image/Image";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import routes from "../../../routes/routes-data";
import {
  OrderStatus,
  OrderStatusIcons,
  PaymentMethods,
} from "../../../utils/statics";
import OrderStatusBadge from "../../orders/partials/order-status-badge";
import PaymentMethodBadge from "../../orders/partials/payment-method-badge";

const LatestOrders = ({ orders }) => {
  console.log(orders);
  const [orderProducts, setOrderProducts] = useState(orders ?? []);
  const { sendRequest: sendRequestStatus, isLoading: statusIsLoading } =
    useRequest();
  /*-------------------------------------------
              Functions
  --------------------------------------------*/

  /*-------------------------------------------
              Async Functions
  --------------------------------------------*/
  const onStatusClick = async (orderProductId, status) => {
    if (!statusIsLoading) {
      let response = await sendRequestStatus({
        url: apiRoutes.ORDER_UPDATE_STATUS(orderProductId),
        method: "PATCH",
        body: {
          status: status,
        },
      });
      if (response) {
        setOrderProducts((prev) =>
          prev?.map((orderProduct) => {
            if (orderProductId == orderProduct.id) {
              orderProduct.order_status = status;
              return orderProduct;
            } else {
              return orderProduct;
            }
          })
        );
      }
    }
  };
  /*-------------------------------------------
                  Lifecycle Hooks
      --------------------------------------------*/
  useEffect(() => {
    setOrderProducts(orders);
  }, [orders]);
  /*-------------------------------------------
                  HTML
      --------------------------------------------*/

  return (
    <PageTableMin
      searchPlaceholder={"Search by order name, id"}
      title={`All Orders`}
      headers={[
        {
          value: "order",
          props: {
            className: "w-10px text-center",
          },
        },
        "",
        "payment method",
        "shipping",
        "subtotal",
        "status",
      ]}
      actions={true}
      body={orderProducts?.map((orderProduct) => [
        {
          value: "#" + orderProduct.order?.id,
          props: {
            className: "w-10px text-center",
          },
        },
        <span className="tb-product">
          <Image
            src={orderProduct.product?.main_image?.access_path}
            alt="order"
            className="thumb"
            style={{
              width: "48px",
              height: "48px",
              objectFit: "cover",
            }}
          />
          <div>
            <p className="title text-capitalize mb-0">
              {orderProduct.product?.name ?? "(Product not found)"}
            </p>
            <p className="mb-0">
              {orderProduct?.product_variant?.product_option_values?.map(
                (optVal, index) => (
                  <span key={optVal.value}>
                    {optVal.value}
                    {index !=
                      orderProduct?.product_variant?.product_option_values
                        ?.length -
                        1 && <span className="px-2">|</span>}
                  </span>
                )
              )}
            </p>
            <p>
              <Currency
                amount={orderProduct.subtotal / orderProduct.quantity}
              />{" "}
              x {orderProduct.quantity}
            </p>
          </div>
        </span>,
        <PaymentMethodBadge method={orderProduct.order?.payment_method} />,
        orderProduct?.shipping,
        orderProduct.subtotal,
        <OrderStatusBadge status={orderProduct.order_status} />,
        [
          ...Object.values(OrderStatus)
            ?.filter((status) => status !== orderProduct.order_status)
            ?.map((status) => {
              //only allow cancel state change for cod payment becuase others need refund to be cancelled
              if (
                (status !== OrderStatus.CANCELLED ||
                  orderProduct.order.payment_method ==
                    PaymentMethods.CASH_ON_DELIVERY) &&
                (orderProduct.order_status !== OrderStatus.CANCELLED ||
                  orderProduct.order.payment_method ==
                    PaymentMethods.CASH_ON_DELIVERY)
              ) {
                return {
                  dropdown: (
                    <DropdownItem
                      key={"order_" + orderProduct.id + "_" + status}
                      tag="a"
                      href="#"
                      onClick={() => onStatusClick(orderProduct.id, status)}
                    >
                      <Icon name={OrderStatusIcons[status]}></Icon>
                      <span>
                        Mark as {_.capitalize(status.replace("_", " "))}
                      </span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      key={"order_" + orderProduct.id + "_" + status}
                      className="nk-tb-action-hidden"
                      onClick={() => onStatusClick(orderProduct.id, status)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"order_" + orderProduct.id + "_" + status}
                        icon={OrderStatusIcons[status]}
                        direction="top"
                        text={`Mark as ${_.capitalize(
                          status.replace("_", " ")
                        )}`}
                      />
                    </li>
                  ),
                };
              } else {
                return {};
              }
            }),
          {
            dropdown: (
              <DropdownItem
                tag={Link}
                to={routes.ORDER_INVOICE(orderProduct.order.id)}
              >
                <Icon name="printer"></Icon>
                <span>View Invoice</span>
              </DropdownItem>
            ),
            tooltip: (
              <li className="nk-tb-action-hidden">
                <TooltipComponent
                  tag={Link}
                  to={routes.ORDER_INVOICE(orderProduct.order.id)}
                  containerClassName="btn btn-trigger btn-icon"
                  id={"invoice_" + orderProduct.id}
                  icon={"printer"}
                  direction="top"
                  text={`View Invoice`}
                />
              </li>
            ),
          },
        ],
      ])}
    />
  );
};

export default LatestOrders;
