import {
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
} from "../Component";

const HeadSection = ({ title, right, left, ...props }) => {
  return (
    <BlockHead size="sm">
      <BlockBetween>
        <BlockHeadContent>
          {left}
          {title && <BlockTitle>{title}</BlockTitle>}
        </BlockHeadContent>
        <BlockHeadContent>{right}</BlockHeadContent>
      </BlockBetween>
    </BlockHead>
  );
};

export default HeadSection;
