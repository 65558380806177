import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import { capitalizeText, searchParamsToObject } from "../../utils/methods";
import HeadSection from "../../components/admin-page-components/HeadSection";
import { DirectMessageStatus } from "../../utils/statics";
import StatusBadge from "./partials/status-badge";
import ViewModal from "./partials/view-modal";

const DirectMessagesIndex = (props) => {
  const [paginationData, setPaginationData] = useState(null);
  const [viewMessage, setViewMesssage] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [directMessages, setDirectMessages] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();
  const { sendRequest: sendRequestStatus, isLoading: statusIsLoading } =
    useRequest();

 

  const getDirectMessages = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.DIRECT_MESSAGE_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response?.data) {
      setDirectMessages(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onStatusClick = async (directMessageId, status) => {
    if (!statusIsLoading) {
      let response = await sendRequestStatus({
        url: apiRoutes.DIRECT_MESSAGE_UPDATE(directMessageId),
        method: "PATCH",
        body: {
          status: status,
        },
      });
      if (response) {
        setDirectMessages((prev) =>
          prev?.map((directMessage) => {
            if (directMessageId == directMessage.id) {
              directMessage.status = status;
              return directMessage;
            } else {
              return directMessage;
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    getDirectMessages();
  }, [getDirectMessages, searchParams]);

  return (
    <>
      <Head title="Direct Messages"></Head>
      <Content>
        <HeadSection title={"Direct Messages"} />
        <PageTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchPlaceholder={"Search by Direct Message name, id"}
          title="All Direct Messages"
          isLoading={isLoading}
          error={error}
          headers={["details", "message", "status"]}
          actions={true}
          body={directMessages?.map((directMessage) => {
            return [
              <div>
                <p className="mb-0 text-muted font-muted">
                  #{directMessage.id}
                </p>
                <span className="text-capitalize ">{directMessage.name}</span>
              </div>,
              <p
                style={{
                  marginBottom: "auto",
                }}
              >
                {directMessage.message.substr(0, 100) + "..."}
              </p>,
              <StatusBadge status={directMessage.status} />,
              [
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => setViewMesssage(directMessage)}
                    >
                      <Icon name={"eye"}></Icon>
                      <span>View Message</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => setViewMesssage(directMessage)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"view_message" + directMessage.id}
                        icon={"eye"}
                        direction="top"
                        text={`View Message`}
                      />
                    </li>
                  ),
                },
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() =>
                        onStatusClick(
                          directMessage.id,
                          directMessage.status === DirectMessageStatus.PENDING
                            ? DirectMessageStatus.ARCHIVED
                            : DirectMessageStatus.PENDING
                        )
                      }
                    >
                      <Icon
                        name={
                          directMessage.status === DirectMessageStatus.PENDING
                            ? "archive"
                            : "info"
                        }
                      ></Icon>
                      <span>
                        Mark as{" "}
                        {directMessage.status === DirectMessageStatus.PENDING
                          ? capitalizeText(DirectMessageStatus.ARCHIVED)
                          : capitalizeText(DirectMessageStatus.PENDING)}
                      </span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() =>
                        onStatusClick(
                          directMessage.id,
                          directMessage.status === DirectMessageStatus.PENDING
                            ? DirectMessageStatus.ARCHIVED
                            : DirectMessageStatus.PENDING
                        )
                      }
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"change_status" + directMessage.id}
                        icon={
                          directMessage.status === DirectMessageStatus.PENDING
                            ? "archive"
                            : "info"
                        }
                        direction="top"
                        text={`Mark as ${
                          directMessage.status === DirectMessageStatus.PENDING
                            ? capitalizeText(DirectMessageStatus.ARCHIVED)
                            : capitalizeText(DirectMessageStatus.PENDING)
                        }`}
                      />
                    </li>
                  ),
                },
              ],
            ];
          })}
          paginationData={paginationData}
          filterOptions={[
            {
              label: "status",
              name: "status",
              options: [
                { value: "", label: "all" },
                ...Object.keys(DirectMessageStatus).map((key) => ({
                  label: capitalizeText(key),
                  value: DirectMessageStatus[key],
                })),
              ],
            },
            // {
            //   label: "sort by",
            //   name: "sort",
            //   options: [
            //     { value: "created_at-desc", label: "Latest" },
            //     { value: "status-asc", label: "Status" },
            //   ],
            // },
          ]}
        />
      </Content>
      <ViewModal
        directMessage={viewMessage}
        show={!!viewMessage}
        onClose={() => {
          setViewMesssage(null);
        }}
      />
    </>
  );
};

export default DirectMessagesIndex;
