import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import DataCard from "./partials/DataCard";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";

import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import DefaultOrderStatistics from "./partials/OrderStatistics";
import LatestOrders from "./partials/LatestOrders";
import Overlay from "../../components/loaders/page-overlay";

const Dashboard = (props) => {
  const [data, setData] = useState([]);
  const { isLoading, sendRequest } = useRequest();
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/
  const getData = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.DASHBOARD_GENERAL,
    });

    if (response) {
      setData(response);
    }
  }, [sendRequest]);

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/
  useEffect(() => {
    getData();
  }, []);
  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <>
      <Head title="Homepage"></Head>
      <Content>
        {isLoading && <Overlay spinner={true} bg />}
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Dashboard
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col xxl="3" sm="6">
              <DataCard
                title="Orders"
                theme={"#13c9f2"}
                total={data?.info?.orders.total}
                diff={data?.info?.orders.diff}
                status={data?.info?.orders.status}
                icon={"package"}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                theme={"#816bff"}
                title="Customers"
                icon={"user"}
                total={data?.info?.customers.total}
                diff={data?.info?.customers.diff}
                status={data?.info?.customers.status}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                theme="#20c997"
                icon={"report-profit"}
                title="Products"
                total={data?.info?.products.total}
                diff={data?.info?.products.diff}
                status={data?.info?.products.status}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                theme="#ff82b7"
                title="Categoriess"
                icon={"list"}
                total={data?.info?.categories.total}
                diff={data?.info?.categories.diff}
                status={data?.info?.categories.status}
              />
            </Col>
            <Col sm="12">
              <Card className="card-full overflow-hidden">
                <div className="nk-ecwg nk-ecwg7 h-100">
                  <div className="card-inner flex-grow-1">
                    <div className="card-title-group mb-4">
                      <div className="card-title">
                        <h6 className="title">Order Statistics</h6>
                      </div>
                    </div>
                    <div className="nk-ecwg7-ck">
                      <DefaultOrderStatistics
                        orderStatistics={{
                          labels: ["Completed", "Processing", "Canclled"],
                          dataUnit: "People",
                          legend: false,
                          datasets: [
                            {
                              borderColor: "#fff",
                              backgroundColor: [
                                "#816bff",
                                "#13c9f2",
                                "#ff82b7",
                              ],
                              data: [
                                data.orders?.completed,
                                data.orders?.processing,
                                data.orders?.cancelled,
                              ],
                            },
                          ],
                        }}
                      />
                    </div>
                    <ul className="nk-ecwg7-legends">
                      <li>
                        <div className="title">
                          <span
                            className="dot dot-lg sq"
                            style={{ background: "#816bff" }}
                          ></span>
                          <span>Completed</span>
                        </div>
                      </li>
                      <li>
                        <div className="title">
                          <span
                            className="dot dot-lg sq"
                            style={{ background: "#13c9f2" }}
                          ></span>
                          <span>Processing</span>
                        </div>
                      </li>
                      <li>
                        <div className="title">
                          <span
                            className="dot dot-lg sq"
                            style={{ background: "#ff82b7" }}
                          ></span>
                          <span>Canclled</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card className="card-full overflow-hidden">
                <div className="nk-ecwg nk-ecwg7 h-100">
                  <div className="card-inner flex-grow-1">
                    <div className="card-title-group mb-4">
                      <div className="card-title">
                        <h6 className="title">Latest Orders</h6>
                      </div>
                    </div>
                    <LatestOrders orders={data?.latest_orders} />
                  </div>
                </div>
              </Card>
            </Col>
            {/* <Col xxl="8">
              <RecentOrders />
            </Col>
            <Col xxl="4" md="8" lg="6">
              <TopProducts />
            </Col> */}
          </Row>
        </Block>
      </Content>
    </>
  );
};

export default Dashboard;
