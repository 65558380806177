import { useState } from "react";
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { Block, Icon, PreviewAltCard, RSelect } from "../Component";
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../table/DataTable";

import Button from "../button/Button";
import Pagination from "../pagination/Pagination";

import { useRef } from "react";
import { searchParamsToObject } from "../../utils/methods";
import Select from "../input/select/select";
import useForm from "../../hooks/use-form";
import { useEffect } from "react";
import Overlay from "../loaders/page-overlay";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";

const PageTable = ({
  headers,
  body,
  actions,
  title,
  isLoading,
  error,
  paginationData,
  searchPlaceholder,
  searchParams,
  setSearchParams,
  filterOptions,
  leftComponent,
  cardClass,
  ...props
}) => {
  const [onSearch, setonSearch] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const searchRef = useRef();
  const { setFields, setOne, inputs, reset } = useForm();

  const onResetFilter = () => {
    let params = searchParamsToObject(searchParams);
    Object.keys(inputs).forEach((key) => {
      delete params[key];
    });
    delete params?.search;
    reset();
    setSearchParams(params);
  };

  const onFilter = () => {
    setSearchParams({
      ...searchParamsToObject(searchParams),
      ...inputs,
      page: 1,
    });
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    setSearchParams({
      ...searchParamsToObject(searchParams),
      search: searchRef.current.value,
      page: 1,
    });
    setonSearch(false);
  };

  useEffect(() => {
    if (filterOptions) {
      let fields = {};
      let isFilt = false;
      filterOptions.forEach((filter) => {
        if (searchParams.get(filter.name)) {
          isFilt = true;
          fields[filter.name] = searchParams.get(filter.name);
        } else {
          fields[filter.name] = "";
        }
      });
      setIsFiltered(isFilt);
      setFields(fields);
    }
  }, [filterOptions, setFields, searchParams]);

  useEffect(() => {
    if (error?.length > 0) {
      addAlert(toast.TYPE.ERROR, error);
    }
  }, [error]);

  return (
    <Block>
      <DataTable className={["min-h-250", cardClass ?? ""].join(" ")}>
        <div className="card-inner">
          <div className="card-title-group">
            {leftComponent ? (
              leftComponent
            ) : (
              <div className="card-title">
                <h5 className="title">
                  {searchParams.get("search")?.length > 0
                    ? `Search results for "${searchParams.get("search")}"`
                    : title}
                </h5>
              </div>
            )}
            <div className="card-tools mr-n1">
              <ul className="btn-toolbar gx-1">
                <li>
                  <Button
                    color={""}
                    href="#search"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setonSearch(true);
                    }}
                    className="btn-icon search-toggle toggle-search"
                  >
                    <Icon name="search"></Icon>
                  </Button>
                </li>
                {filterOptions && (
                  <>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      {/* Advance Filter */}
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="btn btn-trigger btn-icon dropdown-toggle"
                        >
                          <Icon name="filter-alt"></Icon>
                          {isFiltered && (
                            <div
                              className="dot dot-primary"
                              style={{ right: "auto", left: "-0.1rem" }}
                            ></div>
                          )}
                        </DropdownToggle>
                        <DropdownMenu
                          right
                          style={{
                            overflow: "visible",
                          }}
                          className="filter-wg dropdown-menu-xl "
                        >
                          <div className="dropdown-head">
                            <span className="sub-title dropdown-title">
                              Advanced Filter
                            </span>
                            <div className="dropdown">
                              <Button size="sm" color={""} className="btn-icon">
                                <Icon name="more-h"></Icon>
                              </Button>
                            </div>
                          </div>
                          <div className="dropdown-body dropdown-body-rg">
                            <Row className="gx-6 gy-4">
                              {filterOptions?.map((filter, index) => (
                                <Col
                                  sm={
                                    filterOptions.length % 2 == 0
                                      ? 6
                                      : filterOptions.length - 1 == index
                                      ? 12
                                      : 6
                                  }
                                >
                                  <FormGroup className="form-group">
                                    <label className="overline-title overline-title-alt">
                                      {filter.label}
                                    </label>
                                    <RSelect
                                      options={filter.options}
                                      onChange={(e) => {
                                        setOne(filter.name, e.value);
                                      }}
                                      value={
                                        filter.options.find(
                                          (op) =>
                                            op.value == inputs[filter.name]
                                        ) ?? filter.options[0]
                                      }
                                      className={`react-select-container`}
                                      classNamePrefix="react-select"
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          textTransform: "capitalize",
                                        }),
                                        option: (styles) => ({
                                          ...styles,
                                          textTransform: "capitalize",
                                        }),
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              ))}
                            </Row>
                          </div>
                          <div className="dropdown-foot between">
                            <div
                              onClick={onResetFilter}
                              className="clickable"
                              style={{ cursor: "pointer" }}
                            >
                              Reset Filter
                            </div>
                            <Button onClick={onFilter} color={"secondary"}>
                              Filter
                            </Button>
                          </div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {/* SEARCH */}
            <div className={`card-search search-wrap ${onSearch && "active"}`}>
              <form onSubmit={onSearchSubmit} className="search-content">
                <Button
                  color={""}
                  type="button"
                  onClick={() => {
                    setonSearch(false);
                  }}
                  className="search-back btn-icon toggle-search"
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
                <input
                  ref={searchRef}
                  defaultValue={
                    searchParamsToObject(searchParams)?.search ?? ""
                  }
                  type="text"
                  className="border-transparent form-focus-none form-control"
                  placeholder={searchPlaceholder}
                />
                <Button color={""} className="search-submit btn-icon">
                  <Icon name="search"></Icon>
                </Button>
              </form>
            </div>
          </div>
        </div>
        <DataTableBody bodyclass="nk-tb-tnx">
          <DataTableHead className="nk-tb-item">
            {headers?.map((head) => (
              <DataTableRow {...head?.props}>
                <span className="sub-text text-capitalize">
                  {head.value ?? head}
                </span>
              </DataTableRow>
            ))}

            {actions && (
              <DataTableRow className={"text-right"}>
                <span className="sub-text text-capitalize">actions</span>
              </DataTableRow>
            )}
          </DataTableHead>

          {body?.length > 0
            ? body.map((item) => (
                <DataTableItem key={item.id}>
                  {item?.map((value, index) => {
                    if (actions && item.length - 1 == index) {
                      return (
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            {value?.map(({ tooltip }) => tooltip)}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-icon dropdown-toggle btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    {value?.map(({ dropdown }) => (
                                      <li>{dropdown}</li>
                                    ))}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      );
                    } else {
                      return (
                        <DataTableRow {...value?.props}>
                          {value?.value != null ? value.value : value}
                        </DataTableRow>
                      );
                    }
                  })}
                </DataTableItem>
              ))
            : null}
        </DataTableBody>

        {body?.length == 0 && !isLoading && (
          <div className="p-5">
            <div className="text-center">
              <span className="text-muted">No {"records"} found</span>
            </div>
          </div>
        )}

        {isLoading && <Overlay spinner={true} shade />}
        {paginationData && (
          <div className="card" style={{ boxShadow: "none" }}>
            <div className="card-inner">
              <Pagination
                data={paginationData}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            </div>
          </div>
        )}
      </DataTable>
    </Block>
  );
};

export default PageTable;
