import routes from "../../../routes/routes-data";
import { capitalizeText } from "../../../utils/methods";
import {
  AdminRoles,
  BannerTypes,
  DirectMessageStatus,
} from "../../../utils/statics";

const getMenuData = (user) => {
  let isSuperAdmin = user.roles?.some(
    (role) => role === AdminRoles.SUPER_ADMIN
  );
  let routesData = [];

  // General Routes
  routesData = [
    ...routesData,
    {
      heading: "DASHBOARDS",
    },
    {
      icon: "speed",
      text: "General",
      link: "/",
    },
    {
      heading: "General",
    },
    {
      icon: "package-fill",
      text: "Products",
      active: false,
      subMenu: [
        {
          text: "Create Product",
          link: routes.PRODUCT_CREATE,
        },
        {
          text: "Manage Products",
          link: routes.PRODUCT_INDEX,
        },
      ],
    },
    {
      icon: "chat-fill",
      text: "Direct Messages",
      active: false,
      subMenu: [
        {
          text: "All",
          link: routes.DIRECT_MESSAGE_INDEX,
        },
        ...Object.values(DirectMessageStatus).map((status) => ({
          text: capitalizeText(status),
          link: `${routes.DIRECT_MESSAGE_INDEX}?status=${status}`,
        })),
      ],
    },
    {
      icon: "article",
      text: "Newsletter",
      active: false,
      subMenu: [
        {
          text: "Subscribers",
          link: routes.NEWSLETTER_SUBSCRIBERS_INDEX,
        },
      ],
    },
    {
      icon: "bag-fill",
      text: "Orders",
      active: false,
      link: routes.ORDER_INDEX,
    },
    {
      icon: "tag-fill",
      text: "Categories",
      active: false,
      link: routes.CATEGORY_INDEX,
    },

    {
      icon: "img-fill",
      text: "Banners",
      active: false,
      link: routes.BANNER_INDEX,
    },
    // {
    //   icon: "calendar-alt-fill",
    //   text: "Events",
    //   active: false,
    //   subMenu: [
    //     {
    //       text: "All",
    //       link: routes.EVENTS_INDEX,
    //     },
    //     ...linkWithWebsite(routes.EVENTS_INDEX, accessibleWebsites),
    //   ],
    // },
    {
      icon: "user-list-fill",
      text: "Customers",
      link: routes.CUSTOMERS_INDEX,
    },
    {
      icon: "map",
      text: "Carriers",
      link: routes.CARRIER_INDEX,
    },
    {
      icon: "masonry",
      text: "Size Guides",
      link: routes.SIZE_GUIDE_INDEX,
    },
  ];

  if (isSuperAdmin) {
    routesData = [
      ...routesData,
      {
        heading: "Super Admin",
      },
      {
        icon: "account-setting-fill",
        text: "Admins",
        active: false,
        subMenu: [
          {
            text: "Create Admin",
            link: routes.ADMIN_CREATE,
          },
          {
            text: "Manage Admin",
            link: routes.ADMIN_INDEX,
          },
        ],
      },
      {
        icon: "setting-fill",
        text: "Settings",
        link: routes.APP_SETTINGS_NOTIFICATION_CONFIGURATIONS,
        active: false,
      },
    ];
  }
  return routesData;
  // return [
  // {
  //   icon: "activity-round-fill",
  //   text: "Sales",
  //   link: "/sales",
  // },
  // {
  //   icon: "growth-fill",
  //   text: "Analytics",
  //   link: "/analytics",
  // },
  // {
  //   icon: "msg-circle-fill",
  //   text: "Messages",
  //   link: "/app-chat",
  // },

  // {
  //   icon: "bag-fill",
  //   text: "Orders",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "New Oders",
  //       link: "/ecommerce/orders",
  //     },
  //     {
  //       text: "Fullfilled",
  //       link: "#",
  //     },
  //     {
  //       text: "Cancelled",
  //       link: "#",
  //     },
  //   ],
  // },
  // {
  //   icon: "package-fill",
  //   text: "Products",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Create Product",
  //       link: "#",
  //     },
  //     {
  //       text: "Manage Products",
  //       link: "/ecommerce/products",
  //     },
  //   ],
  // },
  // {
  //   icon: "spark-fill",
  //   text: "Flash Deals",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Create Deal",
  //       link: "#",
  //     },
  //     {
  //       text: "Manage Deals",
  //       link: "/ecommerce/products",
  //     },
  //   ],
  // },
  // {
  //   icon: "gift",
  //   text: "Promotions",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Coupons",
  //       link: "/ecommerce/orders",
  //     },
  //     {
  //       text: "Promo Email",
  //       link: "#",
  //     },
  //   ],
  // },
  // {
  //   icon: "wallet-fill",
  //   text: "Payments",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Pending Payments",
  //       link: "/pricing-table",
  //     },
  //     {
  //       text: "Completed Payments",
  //       link: "#/pricing-table",
  //     },
  //   ],
  // },
  // {
  //   icon: "hot-fill",
  //   text: "Ads",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Slider",
  //       link: "#",
  //     },
  //     {
  //       text: "Banner",
  //       link: "#",
  //     },
  //   ],
  // },
  // {
  //   icon: "copy-fill",
  //   text: "Blog",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Create Blog",
  //       link: "#",
  //     },
  //     {
  //       text: "Manage Blogs",
  //       link: "#",
  //     },
  //   ],
  // },
  // {
  //   icon: "emails-fill",
  //   text: "Newsletter",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Create Subscriber",
  //       link: "#",
  //     },
  //     {
  //       text: "Manage Subscriber",
  //       link: "#",
  //     },
  //     {
  //       text: "Send Email",
  //       link: "#",
  //     },
  //   ],
  // },
  // {
  //   icon: "chat-fill",
  //   text: "Support",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "New Messages",
  //       link: "/order-list-default",
  //     },
  //     {
  //       text: "Message History",
  //       link: "/order-list-default",
  //     },
  //   ],
  // },
  // {
  //   icon: "list-index",
  //   text: "Categories",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Sckali Main",
  //       link: "#",
  //     },
  //     {
  //       text: "Sckali Design",
  //       link: "#",
  //     },
  //     {
  //       text: "Sckali Beauty",
  //       link: "#",
  //     },
  //     {
  //       text: "Ckali",
  //       link: "#",
  //     },
  //     {
  //       text: "Sckali Daum",
  //       link: "#",
  //     },
  //     {
  //       text: "Sckali Homeline",
  //       link: "#",
  //     },
  //     {
  //       text: "Sckali Hospitality",
  //       link: "#",
  //     },
  //     {
  //       text: "Tarutti",
  //       link: "#",
  //     },
  //     {
  //       text: "Argansus",
  //       link: "#",
  //     },
  //   ],
  // },
  // {
  //   icon: "shield-check-fill",
  //   text: "Member Center",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Bronze",
  //       link: "/user-list-default",
  //     },
  //     {
  //       text: "Silver",
  //       link: "#",
  //     },
  //     {
  //       text: "Gold",
  //       link: "#",
  //     },
  //     {
  //       text: "Platinum",
  //       link: "#",
  //     },
  //     {
  //       text: "Diamond",
  //       link: "#",
  //     },
  //   ],
  // },
  // {
  //   icon: "users-fill",
  //   text: "Users",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Customers",
  //       link: "/user-list-default",
  //     },
  //     {
  //       text: "Subscribers",
  //       link: "/user-list-default",
  //     },
  //   ],
  // },
  // {
  //   icon: "building-fill",
  //   text: "Outlets",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Pending",
  //       link: "/user-list-default",
  //     },
  //     {
  //       text: "Verified",
  //       link: "/user-list-default",
  //     },
  //   ],
  // },
  // {
  //   icon: "setting-fill",
  //   text: "Settings",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Size Chart",
  //       link: "#",
  //     },
  //     {
  //       text: "Header Message",
  //       link: "#",
  //     },
  //     {
  //       text: "Company Info",
  //       link: "#",
  //     },
  //     {
  //       text: "Main Info",
  //       link: "/ecommerce/settings",
  //     },
  //   ],
  // },
  // ];
};

export default getMenuData;
