import React, { useState } from "react";
import {
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
} from "../../../components/Component";

import { Form, FormGroup, Alert } from "reactstrap";
import Head from "../../../layouts/dashboard/head/Head";
import { useNavigate } from "react-router-dom";
import routes from "../../../routes/routes-data";
import Input from "../../../components/input/input/input";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth-reducer";
import apiRoutes from "../../../routes/api-routes";
const TwoWayAuth = ({ request, onComplete, credentials }) => {
  const { setOne, inputs } = useForm({
    passcode: "",
  });
  const { isLoading, error, sendRequest, validationErros } = request;

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const response = await sendRequest({
        url: apiRoutes.LOGIN_OTP_VERIFY,
        method: "POST",
        body: {
          code: inputs.passcode,
          identity: credentials.identity,
          password: credentials.password,
        },
      });

      if (response) {
        onComplete(response);
      }
    }
  };

  return (
    <React.Fragment>
      <Head title="Login" />
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">Sign-In</BlockTitle>
          <BlockDes>
            <p>
              Please insert the 6 Digits OTP send to {credentials?.phone} &
              {credentials?.email}
            </p>
          </BlockDes>
        </BlockContent>
      </BlockHead>
      {/* On Credential Fails */}

      {error && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {error}
          </Alert>
        </div>
      )}

      <Form className="is-alter" onSubmit={onFormSubmit}>
        <Input
          size="form-control-lg"
          set={setOne}
          name="passcode"
          model={inputs.passcode}
          error={validationErros?.code}
        />
        <FormGroup>
          <Button
            isLoading={isLoading}
            size="lg"
            className="btn-block"
            type="submit"
            color="primary"
          >
            Sign In
          </Button>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};
export default TwoWayAuth;
