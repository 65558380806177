import React, { useState } from "react";
import { Fragment } from "react";
import { Col, FormGroup } from "reactstrap";
import { Icon } from "../../Component";

const PhoneNumberInput = ({
  size,
  label,
  icon,
  className,
  type,
  error,
  rightSide,
  set,
  model,
  name,
  placeholder,
  columnConfig,
  country,
  ...props
}) => {
  let Wrapper = columnConfig ? Col : Fragment;
  console.log(country);
  return (
    <Wrapper {...columnConfig}>
      <FormGroup>
        {label && (
          <div className="form-label-group">
            <label className=" form-label text-capitalize" htmlFor="default-01">
              {label}
            </label>
          </div>
        )}
        <div className="form-control-wrap phone">
          <div className={`form-control ${size} ${error ? "error" : ""}`}>
            {country?.phone_code ? `+${country.phone_code}` : "-"}
          </div>

          <input
            onChange={(e) => {
              set(name, e.target.value);
            }}
            value={model}
            {...props}
            type={"number"}
            className={`form-control ${size} ${error ? "error" : ""}`}
            placeholder={"Phone number without country-code & leading 0"}
          />

          {error && <span className="invalid">{error[0]}</span>}
        </div>
      </FormGroup>
    </Wrapper>
  );
};

export default PhoneNumberInput;
