import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Component";
import ErrorImage from "../../images/gfx/error-504.svg";
import PageContainer from "../../layouts/dashboard/page-container/PageContainer";

const Error403 = () => {
  return (
    <PageContainer>
      <div className="nk-block nk-block-middle wide-xs mx-auto">
        <div className="nk-block-content nk-error-ld text-center">
          <h1 className="nk-error-head">403</h1>
          <h3 className="nk-error-title">Oops! Why you’re here?</h3>
          <p className="nk-error-text">Unauthenticate</p>
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <Button color="primary" size="lg" className="mt-2">
              Back To Home
            </Button>
          </Link>
        </div>
      </div>
    </PageContainer>
  );
};
export default Error403;
