import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../../../components/Component";
import Input from "../../../components/input/input/input";
import { capitalizeText } from "../../../utils/methods";

const ViewModal = ({ customer, onClose }) => {
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/
  let fields = [
    "id",
    "first_name",
    "last_name",
    "country_name",
    "phone_number",
    "email",
  ];
  return (
    <Modal
      isOpen={!!customer}
      toggle={() => {
        onClose();
      }}
      fullscreen
      size="md"
    >
      <ModalHeader
        toggle={() => {
          onClose();
        }}
        close={
          <button
            className="close"
            onClick={() => {
              onClose();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        Customer Info
      </ModalHeader>

      <ModalBody>
        <div className="row g-3">
          {customer &&
            fields?.map((column, index) => (
              <Input
                columnConfig={{
                  sm: index === 0 || index === fields.length - 1 ? 12 : 6,
                }}
                readOnly
                placeholder="-"
                label={capitalizeText(column)}
                model={customer[column]}
              />
            ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ViewModal;
