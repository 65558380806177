import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Col, Icon, OverlineTitle } from "../../../components/Component";
import Input from "../../../components/input/input/input";
import Textarea from "../../../components/input/textarea/Textarea";
import { capitalizeText } from "../../../utils/methods";

const ViewModal = ({ directMessage, show, onClose }) => {
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onClose();
      }}
      fullscreen
      size="md"
    >
      <ModalHeader
        toggle={() => {
          onClose();
        }}
        close={
          <button
            className="close"
            onClick={() => {
              onClose();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        Message Detials
      </ModalHeader>

      <ModalBody>
        <Row className="g-3">
          <Input
            columnConfig={{ sm: 2 }}
            label="ID"
            readOnly={true}
            model={directMessage?.id}
          />
          <Input
            columnConfig={{ sm: 5 }}
            label="Name"
            readOnly={true}
            model={capitalizeText(directMessage?.name)}
          />
          <Input
            columnConfig={{ sm: 5 }}
            label="Email"
            readOnly={true}
            model={directMessage?.email}
          />
          <Input
            columnConfig={{ sm: 6 }}
            label="Phone Number"
            readOnly={true}
            model={directMessage?.phone_number}
          />
          <Col sm={12}>
            <div
              style={{
                borderTop: "2px dashed #c7c7c7",
              }}
            ></div>
          </Col>

          <Textarea
            columnConfig={{ sm: 12 }}
            label="Message"
            readOnly={true}
            model={capitalizeText(directMessage?.message)}
          />
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ViewModal;
