import { useCallback } from "react";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useRequest from "../hooks/use-request";
import PageSpinner from "../components/loaders/page-spinner";

import routes from "../routes/routes-data";
import apiRoutes from "../routes/api-routes";

const NoAdmins = (props) => {
  const { sendRequest } = useRequest();
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const getAdmin = useCallback(async () => {
    const response = await sendRequest({
      url: apiRoutes.ADMIN_COUNT,
    });

    if (response?.admins_count && response.admins_count > 0) {
      navigate(routes.AUTH_LOGIN);
    }
    setIsChecked(true);
  }, [navigate, sendRequest]);

  useEffect(() => {
    getAdmin();
  }, [getAdmin]);

  return <div className="">{isChecked ? <Outlet /> : <PageSpinner />}</div>;
};

export default NoAdmins;
