import apiRoutes from "../../routes/api-routes";
import { AxiosRequest } from "../../utils/request";
import Currency from "./Currency";

const PriceRange = ({
  product: {
    min_standard_price,
    min_current_price,
    max_standard_price,
    max_current_price,
  },
}) => {
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/
  try {
    return (
      <>
        <Currency amount={min_current_price} />
        {min_current_price !== max_current_price && (
          <>
            {" - "}
            <Currency amount={max_current_price} />
          </>
        )}
      </>
    );
  } catch (error) {
    new AxiosRequest().sendRequest({
      url: apiRoutes.ERROR_STORE,
      method: "POST",
      body: {
        message: error.message,
        trace: error.stack,
      },
    });
    return "";
  }
};

export default PriceRange;
