import React, { useCallback, useEffect, useState } from "react";
import {
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
} from "../../components/Component";
import { Row, Col, Alert } from "reactstrap";
import Head from "../../layouts/dashboard/head/Head";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../routes/routes-data";
import useForm from "../../hooks/use-form";
import Input from "../../components/input/input/input";
import useRequest from "../../hooks/use-request";
import Select from "../../components/input/select/select";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-reducer";
import apiRoutes from "../../routes/api-routes";
import ImageInput from "../../components/input/image/image";
import PhoneNumberInput from "../../components/input/phone-number-input/phone-number-input";

const Register = ({ history }) => {
  const [passState, setPassState] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const { isLoading, sendRequest, sendFormRequest, validationErros, error } =
    useRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOne, inputs } = useForm({
    email: "",
    password: "",
    password_confirmation: "",
    name: "",
    username: "",
    phone_number: "",
    country_name: "",
    profile_pic: null,
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("email", inputs.email);
    data.append("password", inputs.password);
    data.append("password_confirmation", inputs.password_confirmation);
    data.append("name", inputs.name);
    data.append("username", inputs.username);
    data.append("phone_number", inputs.phone_number);
    data.append("country_name", inputs.country_name);
    data.append("profile_pic", inputs.profile_pic?.file ?? "");
    if (!isLoading) {
      const response = await sendFormRequest({
        url: apiRoutes.ADMIN_REGISTER,
        body: data,
      });

      if (response) {
        await dispatch(authActions.onLoggedIn(response));
        navigate(routes.DASHBOARD_MAIN);
      }
    }
  };

  const getCountry = useCallback(async () => {
    const response = await sendRequest({
      url: apiRoutes.COUNTRIES_INDEX,
    });

    if (response) {
      setCountries(response);
      setCountryOptions(
        response.map((c) => ({
          value: c.name,
          label: `${c.name} (+${c.phone_code})`,
        }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    getCountry();
  }, [getCountry]);

  return (
    <React.Fragment>
      <Head title="Register" />

      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">Register</BlockTitle>
          <BlockDes>
            <p>Create New Dashlite Account</p>
          </BlockDes>
        </BlockContent>
      </BlockHead>
      <form onSubmit={onFormSubmit}>
        <Row className="g-4">
          <ImageInput
            columnConfig={{ sm: 12 }}
            label="Profile Pic (Optional)"
            set={setOne}
            name="profile_pic"
            model={inputs.profile_pic}
            type="circle"
            error={validationErros?.profile_pic}
          />
          <Input
            columnConfig={{ sm: 12 }}
            label="full name"
            set={setOne}
            name="name"
            model={inputs.name}
            error={validationErros?.name}
          />
          <PhoneNumberInput
            columnConfig={{ sm: 6 }}
            country={countries?.find((c) => c.name == inputs.country_name)}
            label="phone number"
            set={setOne}
            name="phone_number"
            model={inputs.phone_number}
            error={validationErros?.phone_number}
          />
          <Select
            options={countryOptions}
            columnConfig={{ sm: 6 }}
            label="country"
            set={setOne}
            name="country_name"
            model={inputs.country_name}
            error={validationErros?.country_name}
          />

          <Input
            columnConfig={{ sm: 6 }}
            label="email"
            set={setOne}
            name="email"
            model={inputs.email}
            error={validationErros?.email}
          />
          <Input
            columnConfig={{ sm: 6 }}
            label="username"
            set={setOne}
            name="username"
            model={inputs.username}
            error={validationErros?.username}
          />
          <Input
            columnConfig={{ sm: 6 }}
            name="password"
            set={setOne}
            model={inputs.password}
            error={validationErros?.password}
            label="password"
            type={passState ? "text" : "password"}
            rightSide={
              <a
                href="#password"
                onClick={(ev) => {
                  ev.preventDefault();
                  setPassState(!passState);
                }}
                className={`form-icon form-icon-right passcode-switch ${
                  passState ? "is-hidden" : "is-shown"
                }`}
              >
                <Icon name="eye" className="passcode-icon icon-show"></Icon>

                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
              </a>
            }
          />
          <Input
            columnConfig={{ sm: 6 }}
            name="password_confirmation"
            set={setOne}
            model={inputs.password_confirmation}
            error={validationErros?.password}
            label="password confirmation"
            type={passState ? "text" : "password"}
          />
          {error && (
            <div className="">
              <Alert color="danger" className="alert-icon">
                {error}
              </Alert>
            </div>
          )}
          <Col md={6} className="offset-md-3 mt-3">
            <Button
              isLoading={isLoading}
              size="lg"
              className="btn-block"
              type="submit"
              color="primary"
            >
              Sign In
            </Button>
          </Col>
        </Row>
      </form>
      <div className="form-note-s2 text-center pt-4">
        Already have an account?{" "}
        <Link to={routes.AUTH_LOGIN}>
          <strong>Sign in instead</strong>
        </Link>
      </div>
    </React.Fragment>
  );
};
export default Register;
