import { Fragment } from "react";
import { Col, FormGroup } from "reactstrap";

const CheckboxRadio = ({
  size,
  label,
  icon,
  className,
  type,
  error,
  set,
  model,
  name,
  options,
  columnConfig,
  ...props
}) => {
  let Wrapper = columnConfig ? Col : Fragment;

  return (
    <Wrapper {...columnConfig} className={`${className}`}>
      <FormGroup>
        {label && (
          <div className="form-label-group">
            <label className=" form-label text-capitalize" htmlFor="default-01">
              {label}
            </label>
          </div>
        )}
        <div className="form-control-wrap">
          <ul className="custom-control-group g-3 align-center">
            {options.map((option) => (
              <li>
                <div
                  className={`custom-control custom-control-sm ${
                    type == "checkbox" ? "custom-checkbox" : "custom-radio"
                  }`}
                >
                  {type == "checkbox" ? (
                    <input
                      onChange={(e) => {
                        if (typeof model == "boolean") {
                          set(name, !model);
                        } else {
                          let values = [
                            ...(model instanceof Array ? model : []),
                          ];
                          if (values.includes(option.value)) {
                            values = values.filter((o) => o != option.value);
                          } else {
                            values.push(option.value);
                          }
                          set(name, values);
                        }
                      }}
                      checked={
                        typeof model == "boolean"
                          ? model
                          : model?.includes(option.value)
                      }
                      {...props}
                      type={"checkbox"}
                      className={`form-control custom-control-input`}
                      name={name}
                      id={option.value}
                    />
                  ) : (
                    <input
                      onChange={(e) => {
                        set(name, option.value);
                      }}
                      checked={model === option.value}
                      {...props}
                      type={"radio"}
                      className="custom-control-input form-control"
                      name={name}
                      id={option.value}
                    />
                  )}

                  <label
                    className="custom-control-label text-capitalize"
                    htmlFor={option.value}
                  >
                    {option.label}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          {error && <span className="invalid">{error[0]}</span>}
        </div>
      </FormGroup>
    </Wrapper>
  );
};

export default CheckboxRadio;
